.date-range-picker-wrapper {
  position: relative;
  .calendar-icon {
    position: absolute;
    top: 15px;
    left: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    background-image: url("../../public/images/payment/calendar.svg");
    z-index: 1;
  }
}

.DateRangePicker {
  .DateRangePickerInput__withBorder {
    padding: 9px 0 9px 16px;
    border: 1px solid #E3E3E9;
    border-radius: 4px;
    height: 48px;
  }

  .DateInput {
    &:first-child {
      margin-left: 12px;
    }
    .DateInput_input {
      font-family: 'Aktiv Grotesk Corp';
      font-size: 13px;
      line-height: 17px;
      font-weight: normal;
      color: #171718;
      padding: 0;
      text-align: center;
    }
  }
}


// NOTE: the order of these styles DO matter

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: #82e0aa; //background
  color: white; //text
  //border: 1px solid $light-red; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  color: white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}
