@media (max-width: 768px) {
  .modal-right {
    display: none !important;
  }
  .modal-content {
    width: inherit !important;
    height: inherit !important;
  }
  .modal-dialog {
    width: auto !important;
  }
  .modal-two-parts-content {
    min-height: 500px !important;
  }
  .modal-left {
    min-width: inherit !important;
  }
}

.modal-content {
  display: flex;
  align-items: flex-start;
}

.modal-header-box {
  border-bottom: 1px solid #E3E3E9;
  padding: 22px 32px 18px;
  width: 100%;
}

.modal-two-parts-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.modal-right {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  width: 100%;
  padding-bottom: 24px;
}

.modal-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 544px;
  max-height: 500px;
  padding: 24px 32px;
  border-right: 1px solid #E3E3E9;
}

.modal-backdrop {
  background: rgba(23, 23, 24, 0.3);
  backdrop-filter: blur(5px);
  opacity: 1 !important;
}
