.custom-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .custom-pagination-left {
        display: flex;
        align-items: center;
    }

    .custom-pagination-right {
        display: flex;
    }

    .custom-pagination-pages {
        display: flex;
        align-items: center;

        .pagination-dots,
        .pagination-page {
            font-family: 'Aktiv Grotesk Corp';
            font-style: italic;
            font-weight: 400;
            font-size: 13px;
            line-height: 7px;
            color: #8B8B92;
        }

        .pagination-next,
        .pagination-prev {
            display: flex;
            align-items: center;
            font-family: 'Aktiv Grotesk Corp';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            color: #8B8B92;
            cursor: pointer;
        }

        .pagination-prev {
            margin-right: 16px;
        }

        .pagination-next {
            margin-left: 16px;
        }

        .prev-icon {
            cursor: pointer;
            display: inline-block;
            background-repeat: no-repeat;
            width: 8px;
            height: 12px;
            background-image: url("../../../public/images/pagination/prev.svg");
            margin-right: 16px;
        }

        .next-icon {
            cursor: pointer;
            display: inline-block;
            background-repeat: no-repeat;
            width: 8px;
            height: 12px;
            background-image: url("../../../public/images/pagination/next.svg");
            margin-left: 16px;
        }

        .pagination-page {
            display: inline-block;

            padding: 16px;
            cursor: pointer;

            &.page-active,
            &:hover {
                background: #FFFFFF;
                box-shadow: 0 2px 0 #EBECF0;
                border-radius: 4px;
            }
        }

        .pagination-dots {
            padding: 16px;
        }
    }

    select {
        border: 1px solid #E3E3E9;
        border-radius: 4px;
        padding: 12px 16px 12px 20px;
        background: #FFFFFF;
        font-family: 'Aktiv Grotesk Corp';
        font-style: italic;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: #8B8B92;
        margin-right: 32px;

    }

    .page-of {
        color: #8B8B92;
        font-family: 'Aktiv Grotesk Corp';
        font-style: italic;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
    }
}
