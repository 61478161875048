@media (max-width: 768px) {
  .signin-card {
    width: 100% !important;
  }
  .signin-card-body {
    padding: 16px !important;
    width: 100% !important;
  }

}

.signin-wrapper {
  /*
  position: relative;
  width: 100%;
  height: 100%;
   */
}

.signin-card {
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -48px;
}

.signin-card-body {
  justify-content: center;
  width: 417px;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 10px;
}

.signin-logo {
  margin-bottom: 24px;
  text-align: center;
  cursor: pointer;
}

.signin-logo h1 {
  line-height: 48px;
  font-weight: 700;
  font-size: 40px;
}

.password-input-group {
  position: relative;
}

.eye {
  position: absolute;
  top: 14px;
  right: 19px;
  z-index: 10;
  height: 20px;
  align-self: flex-start;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: #F7F7F8 !important;
  color: #F7F7F8;
  border: 1px solid #C7CAD4;
  margin-right: 16px;
}

.checkbox-group label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8B8B92;
  text-align: center;
}

.forgot-password-line {
  clear: both;
  float: none;
  overflow: hidden;
}

.signin-forgot-password-checkbox {
  display: inline-block;
  float: left;
}

.signin-forgot-password-link {
  display: inline-block;
  float: right;
  line-height: 20px;
}

.signin-form {
  width: 100%;
}

.signin-form .form-control {
  min-width: 100%;
  width: 100%;
  height: 48px;
}

.signin-form .invalid-feedback {
  padding-top: 0 !important;
}

.signin-form .input-group {
  min-width: 100%;
}

.signin-input-wrapper {
  width: 100%;
}

.signin-button {
  width: 100%;
  height: 48px;
  padding: 14px 32px;
  background: #5DD38C;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}
.signin-button:active,
.signin-button:hover,
.signin-button:focus {
  background: #67e499;
  border: none;
}

.signin-header {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 128%;
  text-align: center;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
}

.signin-form .form-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #171718;
}

.signin-form .input-group {
  margin-bottom: 26px;
}

.signin-form .form-control.is-invalid{
  background-color: #FFF6F6;
  border: 1px solid #ED5359;
}

.signin-form .invalid-feedback {
  color: #ED5359;
  /*margin-top: 8px;*/
  margin-bottom: -15px;
  font-size: 0.825rem;
}

.signin-form input[type="text"],
.signin-form input[type="email"],
.signin-form input[type="password"] {
  padding: 14px 16px 14px 20px;
  border: 1px solid #E3E3E9;
  background: #F8F8FB;
  border-radius: 4px !important;
  height: 48px;
}

.signin-no-account {
  margin-top: 16px;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #8B8B92
}

.signin-no-account .signin-comment-link {
  margin-left: 5px;
}

.signin-header-forgot {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #171718;
}

.signin-header2-forgot {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  text-align: center;
  color: #171718;
  margin-bottom: 32px;
}

.signin-comment-link {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 128%;
  text-align: center;
  color: #32B767 !important;
  margin-top: 16px;
  text-decoration: none;
}

.signin-text-muted {
  font-weight: 400;
  font-size: 13px;
  line-height: 128%;
  color: #8B8B92;
}

.signin-title {
  color: #171718;
  font-size: 24px;
}

.signin-text {
  color: #171718;
  text-align: center;
}

.signin-spinner-wrapper {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-copy {
  position: absolute;
  width: 100%;
  bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #8B8B92;
}

.signin-card .forgot-password-title {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 128%;
  text-align: center;
  letter-spacing: 0.02em;
  width: 100%;
}

.send-reset-password-button {
  margin-top: 24px;
}

.signup-submit-form {
  margin-top: 10px;
}

.password-description {
  margin: -5px 0 8px 0 !important;
}
