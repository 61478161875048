html, body, #root {
  height: 100%;
}
body,
#root {
  min-height: 100%;
}

body {
  font-family: Aktiv Grotesk Corp, sans-serif;
  font-size: 16px;
  color: #171718;
  //height: 100%;
}

.background {
  z-index: -1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background: linear-gradient(68.66deg, #F2F8FF 0%, #EBF2F4 18.23%, #F6F9FC 44.27%, #F6F3FC 73.44%, #F2F2F4 100%) no-repeat;
}

a {
  color: black !important;
  text-decoration: underline;
}

a:hover {
  cursor: pointer;
}


//.main {
//  display: flex;
//  flex-direction: column;
//  justify-content: flex-start;
//}

.container {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.card {
  border: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}
.main {
  display: flex;
  height: 100%;
  flex-flow: column;
}
.main .main_navbar {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}
.main .page {
  flex: 1 1 auto;
}

a.btn {
  color: #FFFFFF !important;
}

/* default form input styles */
form {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"] {
    background: #FFFFFF;
    border: 1px solid #E3E3E9;
    border-radius: 4px;
    padding: 11px 20px 11px 20px;
    font-size: 16px;

    &:focus-visible {
      outline: none;
    }
  }

  select {
    padding: 11px 20px 11px 20px;
    font-size: 16px;
    border: 1px solid #E3E3E9;
    border-radius: 4px;
    background: #FFFFFF;
    color: #8B8B92;
    height: 48px;

    &:focus-visible {
      outline: none;
    }
  }
}

/* default button styles */
a,
button {
  &.btn {
    padding: 11px 32px 11px 32px;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 48px;
    white-space: nowrap;

    &.btn-success {
      background: #5DD38C;
      border: none;
    }

    &.btn-outline-danger {
      color: #ED5359 !important;
      border: 1px solid #ED5359;

      &:active,
      &:active:focus,
      &:hover,
      &:focus {
        background: none;
        box-shadow: none;
        opacity: 1;
      }
    }

    &.btn-outline-secondary {
      color: #171718 !important;
      border: 1px solid #C7CAD5;

      &:active,
      &:active:focus,
      &:hover,
      &:focus {
        background: none;
        box-shadow: none;
        opacity: 1;
      }
    }

    &.btn-outline-success {
      color: #5DD38C !important;
      border: 1px solid #5DD38C;

      &:active,
      &:active:focus,
      &:hover,
      &:focus {
        background: none;
        box-shadow: none;
        opacity: 1;
      }
    }

    &:active {
      box-shadow: none;
      opacity: 0.6;
    }
  }

  .btn-icon {
    margin-top: -1px;
    margin-right: 10px;
  }
}

h1, h2, h3, h4 {
  letter-spacing: 0.02em;
}

h3 {
  font-size: 20px;
}

textarea.form-control,
textarea {
  padding: 14px 16px 14px 20px;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.form-input-comment {
  color: #8B8B92;
  font-size: 13px;
  margin-top: 8px;
}

.mr14 {
  margin-right: 14px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.pt18 {
  padding-top: 18px !important;
}

.notification-success {
  background-color: #5DD38C !important;
  border-radius: 4px !important;
}

.notification-error {
  background-color: #ED5359 !important;
  border-radius: 4px !important;
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(120%, 0, 0) !important;
  transition: all 0.4s;
}

.proofy-link {
  font-style: normal;
  font-weight: 400;
  line-height: 128%;
  color: #32B767 !important;
  text-decoration: none;
  align-self: center;
  cursor: pointer;
}

.fs-14 {
  font-size: 14px !important;
}

.underline {
  text-decoration: underline !important;
}
