@media (max-width: 768px) {
  .clients_content_section {
    padding: 16px !important;
    margin-bottom: 16px !important;
  }
  .clients_menu {
    flex-direction: column !important;
    gap: 16px !important;
    .btn-success {
      width: 100% !important;
    }
  }
  .sl_block {
    width: 100% !important;
    flex-direction: column !important;
    gap: 16px !important;
  }
  .table_clients_search {
    width: 100% !important;
  }
  .client-choose-type-control {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .link-export {
    width: 100% !important;
  }
  .client-add-credits-group {
    flex-direction: column !important;
    gap: 16px !important;
    width: 100% !important;
  }
  .client-add-credits-control {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.client-add-credits-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.link-export {
  width: auto;
}

.clients_menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //padding-bottom: 16px;
}

.clients_content_section {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px #EBECF0;
  border-radius: 10px;
  flex: none;
  flex-grow: 0;
  margin-bottom: 32px;
}

.clients_content_section .line {
  /*width: 1312px;*/
  height: 1px;
  background: #E3E3E9;
  flex: none;
  /*order: 2;*/
  /*flex-grow: 0;*/
  margin: 16px -32px;
}

.table_clients {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table_clients_search {
  width: 304px;
  height: 48px;
}

.table_clients .t_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: solid 1px #F4F5F9;
  padding: 16px 0px;
}

.table_clients .t_header {
  background: #F4F5F9;
  border-radius: 10px 10px 0px 0px;
}

.t_row:last-child {
  //display: flex;
  //flex-direction: row;
  border-radius: 0px 0px 10px 10px ;
}

.t_row .tc_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.t_row .tc_id {
  margin-left: 16px;
  width: 150px;
  word-break: break-all;
}

.t_row .tc_total {
  width:100px;
}

.t_row .tc_click:hover {
  cursor: pointer;
}

.t_row .tc_email {
  display: flex;
  width: 350px;
}

.t_row .tc_checks {
  display: flex;
  width:100px;
}

.t_row .tc_payments {
  display: flex;
  width:100px;
}

.t_row .tc_date {
  display: flex;
  width: 250px;
}

.t_row .tc_description {
  display: flex;
  width:300px;
}

.t_row .tc_block {
  display: flex;
  width:100px;
}

.t_row .tc_ip {
  display: flex;
  width: 225px;
  word-break: break-all;
}

.t_row .tc_description {
  display: flex;
  width: 250px;
}

.t_row .tc_status {
  display: flex;
  width:200px;
}

.t_row .tc_invoice {
  display: flex;
  width:200px;
}

.client-add-credits-control {
  width: 100px;
  margin-left: 16px;
}

.client-choose-type-control {
  width: 160px;
  margin-left: 16px;
}

.tc_left {
  margin-left: 16px;
}

.sl_block {
  display: flex;
  flex-direction: row;
  align-items: center;
}
