@media (max-width: 768px) {
  .packages {
    margin-bottom: 16px !important;
  }
  .page-payment-adjust {
    padding: 2.5em 1em 0 1em !important;
  }
  .page-payment {
    padding: 0 1em !important;
  }
  .payment-trial {
    flex-direction: column !important;
    height: max-content !important;
    gap: 20px !important;
    padding: 16px !important;
  }
  .payment-box {
    width: 100% !important;
    flex-direction: column !important;
    gap: 16px !important;
  }
  .payment-trial-label {
    margin: auto !important;
  }
  .payment-trial-description {
    width: 100% !important;
    flex-direction: column !important;
    padding: 16px !important;
    margin-right: 0 !important;
  }
  .payment-calculator {
    padding: 16px !important;
    flex-direction: column !important;
    margin-bottom: 16px !important;
  }
  .payment-credits {
    width: auto !important;
    margin-right: 0 !important;
  }
  .payment-credits-item {
    width: 32.8% !important;
    padding: 13px !important;
    flex-direction: column !important;
    align-items: normal !important;
    margin-bottom: 10px !important;
  }
  .payment-summary-result {
    width: auto !important;
    padding-top: 10px !important;
  }
  .payment-summary-switch {
    flex-direction: column !important;
    align-items: center !important;
  }
  .payment-switcher {
    margin: 12px !important;
  }
  .content-padding {
    padding: 24px 16px !important;
  }
  .payment_key_features {
    padding: 24px 16px !important;
  }
  .key_feature_columns {
    flex-wrap: wrap !important;
  }
  .key_feature_column {
    flex: 0 0 100% !important;
  }
  .questions_columns {
    flex-wrap: wrap !important;
  }
  .questions_column {
    flex: 0 0 100% !important;
    margin: 0 !important;
  }
  .payment_common_questions {
    padding: 24px 0 !important;
    margin-bottom: 24px !important;
  }
  .payment-tax-warning {
    margin: 0 16px !important;
  }
  .form-switch {
    padding-left: 1.5em !important;
  }
}

.page-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin: 0ppx;
  padding: 0 64px 0;
}
.page {
  &.page-payment-adjust {
    padding-right: 64px;
    padding-left: 64px;
    padding-bottom: 0;
  }
}
.payment-cards {
  display: flex;
  flex-direction: row;
  margin: 32px 0;
}

.payment-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
  width: 416px;
  height: 506px;
  background: #FFFFFF;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 16px;
}

.payment-trial {
  width: 100%;
  height: 128px;
  background: linear-gradient(90deg, #77D37A 0%, #C0DF84 100%);
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;

  padding: 18px 58px;
}

.payment-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment-trial-label {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 116%;
  letter-spacing: 0.02em;
  color: #171718;
  margin: 0 58px 0 25px;
}

.payment-trial-description {
  margin-right: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  gap: 16px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;

  .price {
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 116%;
    letter-spacing: 0.02em;
    color: #171718;
  }

  .credits {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 128%;
    letter-spacing: 0.02em;
    color: #171718;
  }
}

.btn-trial {
  color: #000 !important;
  &:hover {
    color: #212529 !important;
    background-color: #f9fafb !important;
    border-color: #f9fafb !important;
  }
}

.price-tag {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px;
  width: 352px;
  height: 156px;
  border-radius: 10px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.price-tag-green {
  background: #5DD38C;
}
.price-tag-violet {
  background: #AE5ACC;
}
.price-tag-orange {
  background: #FFBE21;
}

.price-tag-header {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.price-tag-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 128%;
  color: #FFFFFF;
}

.payment-card-header {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 128%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #171718;
  margin: 32px 0px;
}

.payment-card-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
}

.pc-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 24px;
  border-bottom: 1px solid #E3E3E9;
}


.payment-button {
  margin-top: 32px;
  width: 149px;
  height: 48px;
}

.payment-button-green {
  background: #5DD38C;
}

.payment-button-violet {
  background: #AE5ACC;
}

.payment-button-orange {
  background: #FFBE21;
}

.payment_types_section {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
}

.payment_types {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.payment_type {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 0px #EBECF0;
  border-radius: 10px;
  width: 640px;
  height: 591px;
  background-color: #FFFFFF;
  margin: 16px;
}

.payment_key_features{
  display: flex;
  width: 100%;
  background-color: #FFFFFF;
  flex-direction: column;
  padding: 64px;

  h3 {
    margin-bottom: 33px;
  }
}

.key_feature_columns{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  .key_feature_column {
    display: flex;
    flex-direction: column;
  }

  .key_feature{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }

  .key-feature-icon {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url("../../../public/images/payment/key-feature.svg") no-repeat center center;
    margin-right: 17px;
  }

}

.payment_common_questions{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 100px;

  h3 {
    margin-bottom: 48px;
  }
}

.questions_columns{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.questions_column {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  flex: 1;

  .accordion-button {
    padding-left: 58px;
  }

  .accordion-button::after {
    position: absolute;
    left: 25px;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
    color: #171718;
  }

  .accordion-item {
    margin: 0 0 16px 0;

  }
}

.packages {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;

  .package-card {
    width: 32%;
    background-color: #FFFFFF;
    box-shadow: 0 2px 0 #EBECF0;
    border-radius: 10px;
  }

  .package-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: #171718;
    // border-radius: 10px;
    padding-top: 24px;
    padding-bottom: 27px;
    margin: auto;

    .package-head-price {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: 0.02em;
      margin-bottom: 4px;
    }

    .package-head-comment {
      font-size: 13px;
      line-height: 17px;
      margin-bottom: 7px;
    }
  }

  .package-title {
    text-align: center;
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: 0.02em;


    &.package-title-green {
      border-bottom: 1px solid #5DD38C;
    }

    &.package-title-orange {
      border-bottom: 1px solid #FFBE21;
    }

    &.package-title-violet {
      border-bottom: 1px solid #AE5ACC;
    }
  }

  .package-offers {
    margin-bottom: 32px;

    .package-offer:first-child {
      border-top: 1px solid #E3E3E9;
    }

    .package-offer {
      display: flex;
      padding-top: 6px;
      padding-bottom: 6px;
      border-bottom: 1px solid #E3E3E9;

      .package-icon {
        margin-right: 11px;
      }
      .package-offer-text {
        font-size: 16px;
      }
    }
  }

  .package-button {
    text-align: center;

    .btn {
      width: 149px;
      height: 48px;
      font-weight: 400;
      font-size: 16px;
      vertical-align: baseline;
      line-height: 24px;
      color: #171718 !important;

      &.btn-color-green {
        border: 1px solid #5DD38C;
      }

      &.btn-color-orange {
        border: 1px solid #FFBE21;
      }

      &.btn-color-violet {
        border: 1px solid #AE5ACC;
      }
    }
  }
}

.payment-calculator {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 48px;
}

.payment-credits {
  width: 720px;
  margin-right: 32px;
  flex: 1;

  .payment-credits-head {
    margin-bottom: 29px;
    width: 100%;
    h2 {
      font-size: 32px;
      margin-bottom: 8px;
    }

    .payment-credits-head-comment {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .payment-credits-input {
    width: 100%;
    margin-bottom: 29px;

    input {
      width: 100%;
      -moz-appearance: textfield;
      &::placeholder {
        font-size: 16px;
        line-height: 20px;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  .payment-credits-select {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    .payment-credits-item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      background: #F8F8F8;
      box-shadow: 0 2px 0 #EBECF0, 0 2px 0 #C8EFD8;
      border-radius: 10px;
      padding: 16px 24px;
      width: 30%;
      margin-bottom: 16px;
      cursor: pointer;

      .credits-number {
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.02em;
      }

      .credits-text {
        font-size: 13px;
        line-height: 17px;
        color: #8B8B92;
      }

      &.selected {
        color: #5CD38C;
        background-color: #EBF9F0;
        box-shadow: 0 2px 0 #C8EFD8;
      }
    }
  }
}

.payment-tax-warning {
  font-weight: 400;
  font-size: 13px;
  color: #8B8B92;
  margin: 0 46px;
}

.payment-tax-link {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 128%;
  color: #32B767 !important;
  text-decoration: none;
}

.payment-summary-result {
  width: 496px;
}

.payment-summary {
  position: relative;
  width: 100%;
  background: #EBF9F0;
  border-radius: 10px;
  margin-bottom: 8px;

  .content-padding {
    padding: 32px 46px;
  }

  .payment-summary-switch {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(92, 211, 140, 0.4);
  }

  .summary-switch-option {
    letter-spacing: 0.02em;
    font-size: 20px;
    line-height: 26px;
    opacity: 0.3;
    cursor: pointer;

    &.selected {
      opacity: 1;
    }
  }

  .payment-switcher {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #5CD38C;
    border-radius: 20px;
    width: 48px;
    height: 24px;
    margin-left: 16px;
    margin-right: 16px;
    cursor: pointer;
    transition: all 150ms ease-in-out;

    .switch-circle {
      position: absolute;
      display: inline-block;
      top: -1px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background: #5DD38C;
      transition: all 150ms ease;

      &.left {
        top: -1px;
      }

      &.right {
        top: -1px;
        transform: translate(100%, 0);
      }
    }
  }

  .payment-selected-credits {
    border: 1px solid #5CD38C;
    border-radius: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    margin-bottom: 16px;

    .selected-credits {
      font-size: 40px;
      text-align: center;
      letter-spacing: 0.02em;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 4px;
    }

    .selected-comment {
      font-size: 13px;
      color: #8B8B92;
    }
  }

  .payment-per-credit-cost {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 34px;

    .per-credit-price {
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.02em;
      color: #171718;
      margin-right: 16px;
    }

    .per-credit-price-comment {
      font-weight: 400;
      font-size: 13px;
      color: #8B8B92;
    }
  }

  .payment-summary-price {
    min-height: 130px;
    padding-top: 25px;
    padding-bottom: 25px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    .summary-price {
      font-weight: 500;
      font-size: 56px;
      line-height: 56px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #171718;
    }

    .fs-64 {
      font-size: 64px !important;
      line-height: 64px !important;
    }

    .discount {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;

      .old-price {
        text-align: center;
        text-decoration-line: line-through;
        color: #8B8B92;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        margin-right: 15px;
      }

      .new-price {
        background: #5CD38C;
        padding: 6px 20px;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
      }
    }
  }

  .payment-need-more-credits {
    background: rgba(231, 231, 231, 0.5);
    backdrop-filter: blur(35px);

    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 1000;
    opacity: 0;
    transition: opacity .25s;

    &.show {
      visibility: visible !important;
      opacity: 1 !important;
    }

    .label {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 128%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #171718;
    }
  }

  .payment-button {
    margin-top: 0;
    width: 100%;
    .btn {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      vertical-align: baseline;
      line-height: 24px;
    }
  }
}

.payment-loader {
  margin-bottom: 100px !important;
}

.payment-bullet-list {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.payment-bullet {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 16px;

  .bullet-label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 148%;
    display: flex;
    align-items: center;
    color: #171718;
  }
}
