.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.padding-right {
  padding-right: 12px;
}