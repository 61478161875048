.widget-input {
  display: block;
  width: 400px;
  height: 45px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    border: #5DD38C 1px solid;
    box-shadow: 0 0 5px #5DD38C;
    -webkit-box-shadow: 0 0 5px #5DD38C;
    -moz-box-shadow: 0 0 5px #5DD38C;
  }
  &:focus-visible {
    outline: none;
  }
}