@media (max-width: 768px) {
  .modal-dialog {
    margin: auto;
  }
}

  .modal-dialog {
  .modal-content {
    /*box-shadow: 0 2px 0 #EBECF0;*/
    background: #FFFFFF;
    border: none;
    border-radius: 10px;
  }
}

.modal-custom-confirm {
  .modal-content {
    width: 416px;
  }
}

.modal-app-settings-custom {
  .modal-content {
    width: 640px;
  }
}

.modal-upload-file {
  .modal-dialog {
    width: 864px;
    max-width: 864px;
  }
  .modal-content {
    width: 864px;
  }
}

.modal-payment-result {
  .modal-content {
    width: 580px;
    text-align: center;
  }
  .modal-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 128%;
    color: #171718;
  }
  .modal-payment-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
  .form-check-input {
    margin-left: 0 !important;
  }
}

.foo-modal-backdrop {
  -webkit-backdrop-filter: blur(5px) !important;
  backdrop-filter: blur(5px) !important;
}

.modal-clean-delete {
  .modal-dialog {
    min-width: min-content;
  }
}
