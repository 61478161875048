@media (max-width: 768px) {
  .content-section {
    padding: 16px !important;
  }
  .DateRangePicker .DateRangePickerInput__withBorder {
    height: auto !important;
  }
  .content-section-body {
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: #5DD38C #fff;
  }
  .content-section-header {
    margin-bottom: 16px !important;

    &.payment-history-calendar {
      align-items: normal !important;
      flex-direction: column !important;

      h2 {
        margin-bottom: 16px !important;
      }
    }
  }
}

.payment_history_details_table {
  width: 100%;
}

.content-section {
  background-color: #FFFFFF;
  padding: 32px;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;
  margin-bottom: 32px;

  &.pagination-bottom {
    margin-bottom: 16px;
  }

  .content-section-header {
    margin-bottom: 32px;

    h2 {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
}

.content-section-status {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  padding: 4px 14px;
  border-radius: 20px;
  font-size: 13px;
  transition: all .2s ease-in;

  width: max-content;
  height: max-content;

  &.complete {
    color: #5DD38C;
    background-color: #EBF9F0;
  }

  &.fail {
    color: #ED5359;
    background-color: #FFF6F6;
  }
}

.table-styled {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid #E3E3E9;

  thead {
    tr {
      background-color: #F4F5F9;

      th:first-child {
        border-top-left-radius: 10px;
      }

      th:last-child {
        border-top-right-radius: 10px;
      }
    }

    th {
      padding: 16px 32px;
      color: #171718;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      border-bottom: 1px solid #E3E3E9;
    }
  }

  tbody {
    td {
      font-size: 13px;
      color: #171718;
      padding: 32px;
      gap: 32px;
      line-height: 17px;
    }
  }

  .table-no-content {
    text-align: center;
  }
}

.table-styled {
  th {
    &.payment-history-total {
      border-left: 1px solid #E3E3E9;
      font-size: 20px;
      line-height: 26px;
      color: #32B767;
      letter-spacing: 0.02em;
      background-color: #5CD38C40;
    }
  }

  td {
    &.payment-history-total {
      border-left: 1px solid #E3E3E9;
      font-size: 20px;
      line-height: 26px;
      color: #32B767;
      letter-spacing: 0.02em;
      background-color: #5CD38C1A;
    }
  }
}

.content-section-header {
  &.payment-history-calendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
