@media screen and (min-width: 0px) and (max-width: 1550px) {
  .pie-chart-wrapper {
    width: 308px;
    height: 308px;
  }
  .pie-chart {
    top: 60px;
  }
}

@media screen and (min-width: 1550px) and (max-width: 1660px) {
  .pie-chart-wrapper {
    width: 400px;
    height: 400px;
  }
  .pie-chart {
    top: 75px;
  }
}

@media screen and (min-width: 1660px) {
  .pie-chart-wrapper {
    width: 500px;
    height: 500px;
  }
  .pie-chart {
    top: 100px;
  }
}

@media (max-width: 768px) {
  .pie-chart-wrapper {
    display: none !important;
  }

  .cleanFile {
    flex-direction: column !important;
    padding: 16px !important;
    gap: 16px !important;
    min-width: auto !important;
    height: auto !important;
    margin-bottom: 24px !important;
  }

  .cf_tablechart {
    flex-direction: column !important;
  }

  .cf_chart_info-block {
    width: inherit !important;
  }

  .cf_table_row {
    width: inherit !important;
  }

  .cf_table {
    width: inherit !important;
    margin-bottom: 16px !important;
  }

  .cf_btn {
    width: inherit !important;
  }

  .cf_delete {
    margin-top: 16px !important;
  }

  .cf_cleaning {
    width: 100% !important;
  }

  .clean_files_filters {
    margin-left: 8px !important;
  }

  .theme-checkbox {
    align-items: normal !important;
  }

  .clean_files_head {
    margin-bottom: 24px !important;
    .search {
      margin-right: 0 !important;
      width: 100% !important;
      input {
        width: inherit !important;
      }
    }
  }
}

.pie-chart-wrapper {
  position: relative;
}

.pie-chart {
  position: absolute;
}

.pie-chart-cell {
  outline: none !important;
  transition: all 0.15s ease-in-out;
  &:hover {
    opacity: 0.65;
  }
}

.pie-chart-tooltip {
  border-radius: 4px;
  background: #fff;
  opacity: 0.95;
  border: 1px solid #E3E3E9;
  padding: 8px 15px;
  min-width: 100px;
}

.cleanFiles {
  display: flex;
  flex-direction: column;
}

.cleanFile {
  justify-content: space-between;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 32px;
  gap: 32px;
  margin-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;
  min-width: 1340px;
  height: 315px;
}

//.cleanFile-summary .cleanFile-summary-name {
//  width: max-content;
//  margin: 0 auto;
//  white-space: nowrap;
//  overflow: hidden;
//}

.cleanFile-summary {
  overflow: hidden;
  min-width: 272px;



  .cleanFile-summary-name {
    width: 100%;
    white-space: normal;
    overflow: auto;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;


    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #171718;
    justify-content: space-between;
  }

  .cleanFile-file-title {
    margin-right: 5px;
    max-width: 240px;
    word-break: break-all;
  }

  .cleanFile-summary-row {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid #E3E3E9;
    padding-bottom: 4px;
    margin-bottom: 4px;

    .row-title {
      font-size: 14px;
      line-height: 18px;
      color: #8B8B92;
    }

    .row-value {
      font-size: 13px;
      line-height: 128%;
      text-align: right;
      color: #8B8B92;
    }
  }
}

.cleanFile-job-id {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 128%;
  text-align: center;
  color: #32B767 !important;
  margin-top: 16px;
  text-decoration: none;
}

.cleanFile-analyze {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 16px;
  border: 1px solid #E3E3E9;
  border-radius: 10px;
  text-align: center;
  transition: border 150ms linear;

  &:hover {
    border: 1px solid #5DD38C;
  }

  .cleanFile-analyze-text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #171718;
  }
}

button.analyze-button {
  justify-content: center;
  padding: 16px 24px;
  gap: 10px;
  border-radius: 4px;
  background-color: #FFFFFF;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 100ms linear, box-shadow 100ms linear;

  &:disabled {
    background: #F4F5F9;
    border: 1px solid #F4F5F9 !important;
    color: #C7CAD5 !important;
  }

  &.success {
    border: 1px solid #5CD38C;
    color: #5CD38C;

    //&:hover {
    //  background: #5DDC90;
    //  color: #FFFFFF;
    //  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
    //}
  }

  .btn-name {
    display: inline-block;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
  }

  .btn-sub-name {
    display: inline-block;
    width: 100%;
  }
}

button.analyze-button.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 14px 28px;
  gap: 8px;
  border: 1px solid #ED5359;
  border-radius: 4px;
  transition: background-color 150ms linear;

  .btn-name {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    color: #ED5359;
    width: auto;
  }

  &:hover {
    background: #FFF7F7;
    border: 1px solid #ED5359;
  }
}

.icon-question {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-image: url("../../../public/images/clean/question.svg");
  background-position: center;
}

.cf_delete {
  .icon-delete {
    margin-right: 7px;
  }
}


.clean_files_head {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  .search {
    flex-grow: 0;
    margin-right: 32px;
  }

  .clean_files_filters {
    display: flex;

    .clean_files_filter {
      font-size: 16px;
      line-height: 20px;
      color: #8B8B92;
      margin-right: 32px;
      cursor: pointer;

      &.current {
        color: #171718;
      }
    }
  }
}

.cleanFile-is-analyzed {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
}

.cleanFile-info {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 14px 28px 14px 20px;
  gap: 8px;
  background: #F4F5F9;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
  height: 70px;
  margin-bottom: 16px;

  .cleanFile-info-text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #8B8B92;
  }
}

.cleanFile-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  background: #FFF7F7;
  border: 1px dashed #ED5359;
  border-radius: 4px;
  height: 123px;

  h2 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #171718;
    margin-bottom: 16px;

    span {
      color: #ED5359;
    }
  }

  .cleanFile-warning-text {
    font-size: 13px;
    line-height: 17px;
    color: #ED5359;
  }
}

.cf_alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 272px;
  height: 70px;
  margin: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 128%;
}

.cf_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  margin: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.02em;
}

.cf_btn_first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  margin: 0 8px 8px 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.02em;
}

.cf_processing_warning {
  padding: 8px 16px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cf_verified_title {
  font-size: 13px;
  line-height: 20px;
  color: #8B8B92;
}

.cf_table {
  display: flex;
  flex-direction: column;
  width: min-content;
}

.cf_table_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 280px;
}

.cf_table_cell {
  margin: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.cf_table_name {
  width: 172px;
}

.cf_table_value {
  display: flex;
  flex-direction: row;
  width: 99px;
  justify-content: flex-end;
}

.cf_table_percent {
  display: flex;
  flex-direction: row;
  width: 99px;
  justify-content: flex-end;
  color: #767678;
}

.cf_delete {
  width: 272px;
  height: 50px;
}

.cf_processing_text {
  color: #FFFFFF;
  margin-right: 12px;
}

.cf_clean {
  height: 270px;
  width: 456px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;

  /* Fill / Green 1 */

  background: #5DD38C;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 1;
  margin-left: 32px;
}

.cf_cleaning {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 272px;

  .cf_cleaning_btn {
    margin-top: 0;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 148%;
    height: auto;
    padding: 14px 32px;
  }
}

.cf_validation_results {
  align-items: flex-start;
  padding: 32px 20px;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
  box-sizing: border-box;

  /* Inside Auto Layout */

  flex: none;
  align-self: stretch;
  flex-grow: 1;
  margin-left: 32px;

  .cf_learn_more {
    display: flex;
    align-content: center;
    gap: 8px;

    font-size: 13px;
    line-height: 17px;
    color: #8B8B92
  }
}

.cf_tablechart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cf_analyzed {
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 1;
  margin-left: 32px;
}

.dropzone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 100px;
  background: rgba(93, 211, 140, 0.1);
  border: 1px solid #5DD38C;
  border-radius: 4px;
  box-sizing: border-box;
  flex: none;
  align-self: stretch;
  flex-grow: 1;
  margin: 12px 0;
}

.dropzone:hover {
  cursor: pointer;
}

.dropzone-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropzone-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #5DD38C;
  margin-left: 8px;
}

.download-modal {
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
  width: 864px;
}

.dm-header-section {
  display: flex;
  flex-direction: column;
  background: linear-gradient(68.66deg, #F2F8FF 0%, #EBF2F4 18.23%, #F6F9FC 44.27%, #F6F3FC 73.44%, #F2F2F4 100%);

}

.dm-header {
  padding: 48px 32px 16px 32px;
}

.dm-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 32px 0px 32px;
}

.dm-content {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 32px;
}

.dm-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFF9EA;
  padding: 32px;
}

.preset-h1 {
  padding: 32px 0px 8px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 128%;
  color: #171718;
}

.preset-h2 {
  padding: 8px 0px 8px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 128%;
  color: #171718;
}

.preset-text {
  padding: 8px 0px 8px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #171718;
}

.preset-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.preset-text-ok {
  color: #32B767;
  padding: 0px 12px;
}

.preset-header-text {
  padding: 0px 12px;
}

.preset-text-cancel {
  padding: 0px 12px;
}

.purchase-file-modal {
  display: flex;
  flex-direction: column;
  padding: 32px 52px;
  justify-content: center;
  align-items: center;
}

.pfm-header {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 128%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #171718;
  margin: 16px 0;
}

.pfm-content {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 148%;
  text-align: center;
  color: #171718;
}

.pfm-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 16px;
}

.pfm-btn {
  width: 100%;
  margin-top: 16px;
}

.pfm-btn-smalltext {
  font-size: 10px;
}

.delete-file-modal {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
}

.dfm-header {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.02em;
}

.dfm-warning {
  margin: 32px 0px;
  padding: 20px 24px;
  height: 60px;
  background: #FFF7F7;
  border: 1px dashed #ED5359;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #ED5359;
}

.bum-warning {
  margin: 32px 0;
  padding: 20px 24px;
  background: #FFF7F7;
  border: 1px dashed #ED5359;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #ED5359;
}

.dfm-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #171718;
}

.dfm-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 32px 0px 0px 0px;
}

.no-results-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;

  border: 1px solid #E3E3E9;
  border-radius: 10px;

  margin-bottom: 16px;
}

.upload-modal {
  border-radius: 10px;
}

.clean-upload-card {
  margin: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  gap: 4px;
  background: #FFFFFF;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
}

.clean-upload-card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  margin-left: 16px;
}

.clean-need-help {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 128%;
  text-align: center;
  color: #8B8B92;
  padding-top: 8px;
}

.clean-modal-header {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #171718;
  margin-bottom: 32px;
  width: 100%;
}

.clean-modal-list {
  padding-left: 16px;

  li {
    font-size: 16px;
    margin-bottom: 8px;
  }

  a.convert-learn {
    color: #32B767 !important;
    text-decoration: none;
  }
}

.cf_fail_message_section {
  min-width: 944px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
}

.cf_fail_header {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 128%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #171718;
}

.cf_fail_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  text-align: center;
  color: #171718;
  margin: 32px 0px;
}

.cf_fail_buttons {
  display: flex;
  flex-direction: row;
}

.cf_short_height_btn {
  width: 272px;
  height: 50px;
}

.cf_short_height_fat_btn {
  width: 100%;
  height: 50px;
}

.cf_fat_btn {
  width: 100%;
  height: 73px;
}

.upload-progress {
  width: 100%;
}

.cleaning-progress {
  height: 20px;
  width: 100%;
  border-radius: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.parsing-progress {
  width: 300px;
  margin-top: 12px;
}

.clean-header {
  margin-bottom: 16px;
}

.clean-header h1 {
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #171718;
}

.clean-subheader {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clean-subheader h3 {
  color: #171718;
  line-height: 20px;
  font-size: 16px;
}

.btn.add-list {
  padding: 14px 28px 14px 20px;
  background: #5DD38C;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  width: 192px;
}

.file-upload-progress {
  width: 100%;
  margin-bottom: 4px;

  .file-upload-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .file-upload-name {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
    word-break: break-all;
  }

  .file-upload-error {
    font-weight: 400;
    font-size: 13px;
    color: #ED5359;
  }

  .upload-progress {
    margin-bottom: 8px;
  }

  .file-upload-progress-icon {
    margin-left: 8px;
  }
}

.upload-progress {
  border-radius: 20px;
  height: 8px;
}

.progress-bar.bg-danger {
  background-color: #ED5359;
}

.extended-download-modal {
  .modal-dialog {
    //width: 864px;
    max-width: 864px;
  }
}

.download-modal-extended {
  width: 100%;

  .download-modal-content {
    background: #FFFFFF;
    padding: 32px;
    border-top: 1px solid #E3E3E9;
  }

  .custom-options-content {
    background: #F4F5F9;
    border-top: 1px solid #E3E3E9;
    padding: 16px;
  }

  .custom-options-excludeDuplicates {
    height: 26px;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;

    .form-check-input {
      height: 26px;
      width: 48px;
      border-radius: 2em;
      background-size: contain;
    }
  }

  .download-modal-extended-head {
    padding-top: 48px;
    padding-left: 32px;
    padding-right: 32px;
    background: linear-gradient(68.66deg, #F2F8FF 0%, #EBF2F4 18.23%, #F6F9FC 44.27%, #F6F3FC 73.44%, #F2F2F4 100%);
    border-radius: 10px 10px 0 0;

    h1 {
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 0.02em;
      color: #171718;
    }
  }

  .download-modal-header {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #171718;
    margin-bottom: 32px;
  }

  .download-tabs {
    display: flex;
    height: 73px;
    background: linear-gradient(68.66deg, #F2F8FF 0%, #EBF2F4 18.23%, #F6F9FC 44.27%, #F6F3FC 73.44%, #F2F2F4 100%);
    box-shadow: 0 15px 50px rgba(22, 34, 78, 0.1);

    .download-tab {
      display: flex;
      align-items: center;
      border: 1px solid #E3E3E9;
      width: 50%;
      padding-left: 32px;
      cursor: pointer;
      transition: background-color 100ms linear, border 100ms linear;

      &:first-child {
        border-radius: 10px 0 0 0;
        border-right: none;
      }

      &:last-child {
        border-radius: 0 10px 0 0;
        border-left: none;
      }

      &.custom-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .sub-name {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.02em;
          color: #8B8B92;
        }
      }

      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #8B8B92;
        margin-bottom: 0;
      }

      &.selected {
        background: #5DD38C;
        border: 1px solid #5DD38C;

        h2 {
          font-style: italic;
          color: #FFFFFF;
        }

        .sub-name {
          color: #FFFFFF;
        }
      }
    }
  }

  .download-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    background: #EBF9F0;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid #5DD38C;

    .btn {
      width: 272px;
    }

    &.custom-download {
      .theme-checkbox {
        label {
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #171718;
        }
      }

      .btn {
        font-style: italic;
      }
    }
  }
}

.presets-description {
  display: none;
  padding-top: 40px;
  padding-bottom: 16px;

  .description-h1 {
    font-size: 24px;
    line-height: 31px;
    color: #171718;
    margin-bottom: 16px;
  }

  .description-text {
    font-size: 16px;
    line-height: 20px;
    color: #171718;
    margin-bottom: 16px;
  }

  .link-text-green {
    color: #32B767;
    text-decoration: none;
  }

  .description-h2 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #171718;
    margin-bottom: 16px;
  }

  .description-preset-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  &.open {
    display: block;
  }
}

.custom-export-section {
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.02em;
    color: #171718;
    margin-bottom: 24px;
  }

  .all-columns {
    width: 100%;
    margin-bottom: 24px;
    padding-left: 21px;
  }

  .columns-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 8px;
    padding-left: 21px;

    .theme-checkbox {
      display: flex;
      align-items: center;
      white-space: nowrap;
      min-width: max-content;
    }
  }

  .custom-export-settings {
    margin-bottom: 32px;
  }

  .exclude-duplicates-section {
    padding-left: 20px;
    margin-top: 18px;
  }

  .custom-options-box {
    background: #FFFFFF;
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0px 2px 0px 0px #EBECF0;
  }

  .custom-options-title {
    margin-left: 20px;
  }
}

.theme-checkbox {
  position: relative;
  display: flex;
  align-items: center;

  input[type=checkbox] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #5DD38C;
    border-radius: 2px;
    box-sizing: border-box;
    margin-right: 16px;
    cursor: pointer;
  }

  label {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #171718;
    cursor: pointer;
  }

  &.select-all {
    label {
      font-weight: 500;
    }
  }

  input[type=checkbox]:checked + label::before {
    content: "";
    display: block;
    position: absolute;
    text-align: center;
    height: 20px;
    width: 20px;
    left: 0;
    top: 0;
    background: #5DD38C;
    border-radius: 2px;
    border: 1px solid #5DD38C;
    box-sizing: border-box;
  }

  input[type=checkbox]:checked + label::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
    display: block;
    position: absolute;
    left: 3px;
    top: 3px;
  }
}

.settings-accordion {
  margin-bottom: 8px;

  .settings-accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    gap: 16px;
    background: #FFFFFF;
    border: 1px solid #E3E3E9;
    border-radius: 4px;

    .settings-accordion-show-options {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: #8B8B92;
      }

      .icon {
        margin-left: 14px;
      }
    }

    .small-text {
      color: #8B8B92;
      font-size: 13px;
      line-height: 17px;
      font-weight: normal;
    }
  }

  &.selected-accordion {
    .settings-accordion-header {
      background: #F4F5F9;
      border: 1px solid #E3E3E9;
      border-radius: 4px;

      .theme-checkbox {
        label {
          font-weight: 500;
        }
      }
    }
  }

  .options-list {
    display: none;
    padding-left: 20px;
    padding-right: 20px;

    &.show-options {
      display: block;
    }
  }

  .settings-accordion-option {
    display: flex;
    padding-bottom: 16px;
    width: 100%;
    border-bottom: 1px solid #E3E3E9;
    margin-top: 16px;

    .option-checkbox {
    }

    .option-description {
      flex: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      color: #8B8B92;
    }
  }
}

.cf_validation_cleaned {

}

.icon {
  &.icon-arrow-up {
    display: inline-block;
    background-repeat: no-repeat;
    width: 9px;
    height: 5px;
    background-image: url("../../../public/images/arrow-down.svg");
    background-position: center;
  }

  &.icon-arrow-down {
    display: inline-block;
    background-repeat: no-repeat;
    width: 9px;
    height: 5px;
    background-image: url("../../../public/images/arrow-down.svg");
    background-position: center;
    transform: rotate(180deg);
  }

  &.icon-alert-circle {
    display: inline-block;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    background-image: url("../../../public/images/clean/alert-circle.svg");
    background-position: center;
  }
}

.copy-fileName-icon {
  cursor: pointer;
}

.cf_chart_info-block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disabled {
  color: #BABABD !important;
}

.clean-btn-icon {
  margin-right: 8px;
}

.cf-optional-text {
  color: #8B8B92 !important;
  opacity: 0.6;
}