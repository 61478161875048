@media (max-width: 768px) {
  .signup-success-content {
    width: 100% !important;
  }
}


.signup-terms-of-use-check {
    .invalid-feedback {
      margin-left: -25px !important;
    }
}

.signup-alert {
  padding: 1em;
  margin: 24px 0 0;
}

.signup-success-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 416px;
  background-color: #ffffff;
  padding: 48px 32px;
  border-radius: 10px;
}

.signup-success-title {
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 8px;
  color: #171718;
}

.signup-icon {
  width: 64px;
  align-self: center;
  margin-bottom: 24px;
}

.signup-success-button {
  align-self: center;
  width: 199px;
  margin-top: 24px;
}
