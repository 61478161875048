@media (max-width: 768px) {
  .page-menu {
    .container {
      width: auto !important;
      h1 {
        margin-bottom: 0 !important;
      }
    }
    align-items: center !important;
    margin-bottom: 16px !important;
  }
  .page-create-app {
    padding: 16px !important;
    .content-padding {
      padding: 0 !important;
    }
    .apps-padding {
      padding: 0 !important;
    }
    .create-app-buttons {
      flex-direction: column !important;
      .btn-outline-secondary {
        width: 100% !important;
        margin: 0 0 16px 0 !important;
      }
      .btn-success {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }
  .app-cards-select {
    margin: 0 0 16px 0 !important;
  }
  .app-cards-created {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .app-card-created {
    width: 100% !important;
    margin: 0 0 16px 0 !important;
  }
  .app-card {
    width: 100% !important;
    margin: 0 !important;
  }
  .select-app-type-header {
    margin: 0 0 16px 0 !important;
  }
  .app-js-widget-title {
    margin-bottom: 24px !important;
  }
  .input-group-with-button {
    display: flex !important;
    flex-direction: column !important;
    .input-group-with-button-input {
      width: 100% !important;
      margin-bottom: 16px !important;
    }
    .input-group-with-button-button {
      width: 100% !important;
      margin-left: 0 !important;
      .btn-success {
        width: 100% !important;
      }
    }
  }
  .ca-description-block {
    .ca-description-text {
      width: 100% !important;
    }
  }
  .ca-throttle-usage-settings {
    margin-bottom: 16px !important;
    .ca-allow-up-to {
      display: flex !important;
      flex-direction: column !important;
      margin: 0 !important;
    }
    .form-label {
      margin: 16px 0 10px 0 !important;
    }
    .form-control {
      width: 100% !important;
    }
    .btn-success {
      margin-top: 16px !important;
      width: 100% !important;
    }
    .ca-verifications-per {
      margin: 0 !important;
      display: flex !important;
      flex-direction: column !important;
      .form-select {
        width: 100% !important;
      }
    }
  }
  .app-usage-stats-header {
    .stats-date-range {
      width: 100% !important;
    }
  }
  .custom-api-key {
    margin-bottom: 16px !important;
    .collapse-icon {
      top: 20px !important;
    }
    .custom-api-key-header {
      height: auto !important;
      padding: 16px !important;
      h3 {
        margin-bottom: 0 !important;
      }
    }
    .custom-api-key-content {
      padding: 16px !important;
    }
  }
  .au-js-code {
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: #5DD38C #fff;
  }

  .first-apps {
    padding: 24px 0 !important;
    .page-apps {
      margin: 0 !important;
      .apps-create-first-title {
        margin-bottom: 24px !important;
      }
    }
  }
}

.apps-create-first-title {
  margin-bottom: 32px;
}

.app-js-widget-title {
  margin-bottom: 3rem;
}

.page-apps {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 64px;
}

.legacy-credentials {
  font-size: 13px;
  color: #8B8B92;
}

a.app-card {
  text-decoration: none;
}

.app-card-progress {
  width: 321px;
  height: 8px;
  background: #5DD38C;
  border-radius: 20px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 16px;
}

.page-create-app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 32px;
  padding-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;
}

.ca-input-throttle {
  display: block;
  width: 100%;
  margin: 0 16px;
}

.ca-input-domain {
  width: 100%
}
.ca-input-domain-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ca-input-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #171718;
  margin-bottom: 16px;
}

.create-app-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%
}
.ca-btn {
  width: 152px;
  height: 48px;
  margin-left: 16px;
}
.ca-small-btn {
  width: 95px;
  height: 48px;
  margin-left: 16px;
}

.page-create-app {
  input[type="text"], input[type="number"], select {
    padding: 11px 20px 11px 20px;
    font-size: 16px;
  }

  .content-padding {
    padding-left: 32px;
    padding-right: 32px;
  }

  .apps-padding {
    padding-left: 84px;
    padding-right: 84px;
  }
}

.ca-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;

  .btn-add {
    width: 94px;
    font-size: 16px;
    padding: 11px 32px;
  }

  label {
    cursor: pointer;
  }
  input[type="checkbox"] {
    cursor: pointer;
  }
}
.ca-form .line {
  height: 1px;
  background: #E3E3E9;
  flex: none;
  margin-bottom: 32px;
}

.ca-form label {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
}

.app-cards-created {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  // margin: 32px 0;
  padding: 16px 16px;
  background: #FFFFFF;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;
}

.app-cards,
.app-cards-select {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 48px;
  width: 100%;
  gap: 15px;
}

.app-cards-select {
  margin-top: 0;
}

.app-card-enabled {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.app-card-disabled {
  background: #F7F7F8;
  box-shadow: 0px 2px 0px #EBECF0;
}
.app-card-disabled:hover {
  cursor: pointer;
}

.app-card-created {
  width: 385px;
  height: 184px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  padding: 32px 32px 48px;
  background: #FFFFFF;
  border: 1px solid #E3E3E9;
  box-sizing: border-box;
  border-radius: 10px;
}

.app-card-created:hover {
  cursor: pointer;
}

.app-card {
  width: 288px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0;
  padding: 32px 32px 24px;
  background: #fff;
  //background: transparent;
  border: 1px solid #E3E3E9;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  // box-shadow: 0 2px 0 #EBECF0;
  transition: all 0.15s ease-in-out;
}

.app-card::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 10px;
  //box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  transition: opacity 0.15s ease-in-out;
}

.app-card:hover {
  //box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid #5DD38C !important;
}

.app-card.app-card-selected {
  background: #FFFFFF;
  border: 1px solid #5DD38C;
  // box-shadow: 0px 7px 10px #EBECF0;
}

.app-zapier {
  opacity: 0.5;
  pointer-events: none;
  background: #e8e8e8;
  box-shadow: none !important;
  border: 1px solid #ddd;
}

.zap-icon {
  filter: grayscale(100%);
}

.app-type-description {
  font-size: 13px;
  line-height: 17px;
  color: #8B8B92;
}

.app-card-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
}
.acc-header {
  margin-left: 16px;
}
.acc-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #171718;
  margin-bottom: 4px;
}
.acc-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 128%;
  color: #8B8B92;
}

.app-card-header-text {
  margin-left: 16px;
}

.ca-form-row {
  margin-bottom: 32px;
  width: 100%;

  .ca-row-comment {
    color: #8B8B92;
    font-size: 13px;
    margin-top: 8px;
  }
}

.select-app-type-header {
  margin-bottom: 20px;

  h3 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #171718;
  }
}

.ca-low-balance-block {
  margin-bottom: 32px;
}

.ca-form-low-balance {
  margin-bottom: 16px;
  h4 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 400;
  }

  p {
    font-size: 13px;
    line-height: 17px;
    color: #8B8B92;
  }
}

.ca-low-balance-check {
  label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
  input[type="checkbox"] {
    margin-right: 16px;
    border-radius: 2px;
    width: 20px;
    height: 20px;
  }
}

.ca-min-threshold {
  label {
    font-weight: 400;
  }
}

.ca-send-notifications {
  label {
    font-weight: 400;
  }
}

.ca-form {
  small.form-text {
    margin-top: 8px;
    font-size: 13px;
    line-height: 17px;
    color: #8B8B92;
  }
}

.ca-description-block {
  margin-bottom: 16px;
  h4 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .ca-description-text {
    width: 50%;
    font-size: 13px;
    line-height: 17px;
    color: #8B8B92;
  }
}

.ca-auth-domain-input::placeholder {
  color: #C7CAD4;
}

.ca-throttle-usage-settings {
  margin-bottom: 16px;
  label {
    display: inline-block;
    margin-right: 16px;
    font-size: 16px;
  }

  input {
    display: inline-block;
    border: 1px solid #E3E3E9;
    border-radius: 4px;
  }

  select {
    display: inline-block;
    border: 1px solid #E3E3E9;
    border-radius: 4px;
  }

  .ca-allow-up-to {
    display: inline-block;
    margin-right: 32px;

    input {
      width: 100px;
    }
  }

  .ca-verifications-per {
    display: inline-block;
    margin-right: 32px;

    .ca-verifications-per-time {
      width: 126px;
      margin-right: 16px;
    }

    .ca-verifications-per-type{
      width: 119px;
      margin-right: 16px;
    }
  }
}

.ca-throttle-usage-comment:disabled {
  color: #8B8B92;
  font-size: 16px;
  background-color: #F4F5F9;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
}

.ca-throttle-usage-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.ca-throttle-usage-tag {
  color: #8B8B92 !important;
  font-size: 16px;
  background-color: #F4F5F9 !important;
  border: 1px solid #E3E3E9 !important;
  border-radius: 4px;
  font-weight: 400 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: min-content;
  gap: 5px;
}

.ca-throttle-usage-close {
  height: 12px;
  width: 12px;
  &:focus {
    box-shadow: none;
    border: none;
  }
}

.error-text {
  color: #ED5359;
  margin: 1em 0;
  font-size: 0.875em;
}

.input-group-with-button {
  display: block;
  width: 100%;

  .input-group-with-button-input {
    display: inline-block;
    width: calc(100% - 126px);
  }

  .input-group-with-button-button {
    display: inline-block;
    float: right;
    margin-left: 32px;
  }
}

.page-apps-list {
  width: 100%;
}

.search {
  position: relative;
  flex-grow: 1;

  .search-icon {
    position: absolute;
    top: 15px;
    left: 18px;
  }

  input[name="search"] {
    padding: 14px 16px 14px 50px;
    height: 48px;
    font-size: 16px;
    width: 304px;
  }
}

.apps-filter {
  display: flex;

  .filter {
    select {
      padding: 11px 20px 11px 20px;
      font-size: 16px;
      height: 48px;
      width: 320px;
    }
  }
}

.app-application-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: #171718;
}

.first-apps {
  padding: 48px 32px;
}
