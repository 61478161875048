@media (max-width: 768px) {
  .MuiTablePagination-selectRoot {
    margin: 0 5px 0 0 !important;
  }
  .MuiTablePagination-actions {
    margin-left: 0 !important;
  }
  .light-small-text {
    margin-top: 24px;
  }
  .page-verify {
    flex-direction: column !important;
  }
  .input-card {
    min-width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 16px !important;
    margin-right: 0 !important;
    padding: 16px !important;
  }
  .result-card {
    min-width: auto !important;
  }
  .t_row .t_time {
    display: none !important;
  }
  .result-card {
    .menu {
      flex-wrap: wrap !important;
      gap: 16px !important;
      padding: 16px !important;
    }
    .btn.export-to-csv {
      width: 100% !important;
    }
    .table_verify {
      margin: 16px !important;
      .t_row {
        padding-left: 16px !important;
        .t_result {
          width: 130px !important;
        }
      }
    }
  }
  .result-history-search {
    width: 100% !important;
    .input-group {
      margin: 0 0 16px 0 !important;
      width: 100% !important;
    }
  }
  .single-proof-btn {
    .btn-success {
      width: 100% !important;
    }
  }
  .table_verify_search {
    width: 100% !important;
  }
  .clear-history-btn {
    padding: 0 !important;
    top: 3px !important;
    left: 5px !important;
  }
}

.page-verify {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.light-small-text {
  display:flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #8B8B92;
}

.no-emails-text {
  display:flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #8B8B92;
}

.table_verify {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 32px 32px 32px;
}

.table_verify_search {
  width: 304px;
  height: 48px;
}

.table_verify .t_row {
  display: flex;
  flex-direction: row;
  border: solid 1px #F4F5F9;
  padding-top: 16px;
  padding-left: 32px;
  padding-bottom: 16px;
  vertical-align: middle;
}

.table_verify .t_row.t_header {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
}

.t_row:last-child {
  border-radius: 0px 0px 10px 10px ;
}

.table_verify .t_header {
  background: #F4F5F9;
  border-radius: 10px 10px 0px 0px;
}

.t_row .t_email {
  width:400px;
}

.t_row .t_time {
  display: flex;
  width:220px;
  align-items: center;
  justify-content: center;
}

.t_row .t_result {
  display: flex;
  width:150px;
  align-items: center;
  justify-content: center;
}

.t_result .t_status {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;


  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;

  height: 28px;

  /* Fill / Yellow */

  border-radius: 20px;
}

.t_result .t_unknown {
  background: #F4F5F9;
}
.t_result .t_try_again {
  background: #F4F5F9;
}
.t_result .t_valid {
  background: #5DD38C;
}
.t_result .t_invalid {
  background: #ED5359;
}
.t_result .t_accept_all {
  background: #FFBE21;
}
.t_result .t_pending {
  background: #FFBE21;
}
.t_result .t_disposable {
  background: #AE5ACC;
}
.t_result .t_unpaid {
  background: #ff3322;
}
.t_result .t_bulk {
  background: #ff3322;
}

.form-floating {
  .enter-mails-control {
    margin: 16px 0;
    height: 168px;
    resize: none;
  }
}

.input-card {
  display: flex;
  flex-direction: column;
  min-width: 416px;
  max-width: 416px;
  height: 410px;
  padding: 32px;
  margin-right: 32px;
  border: 1px solid #E3E3E9;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
}

.input-card h3 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin-bottom: 16px;
}

.input-card p {
  font-size: 16px;
  line-height: 20px;
}

.result-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #FFFFFF;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;
  margin-bottom: 16px;
  min-width: 864px;
  width: 100%;
}

.results-container {
  width: 100%;
}
.result-card {
  .btn.export-to-csv {
    padding: 11px 32px;
    width: 180px;
  }
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 32px;
    border-bottom: solid 1px #E3E3E9;

    h3 {
      font-size: 20px;
      letter-spacing: 0.02em;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .result-history-header {
    display: inline-block;
    float: left;
  }

  .result-history-search {
    display: inline-block;
    float: right;
    width: 520px;

    .input-group {
      margin-right: 32px;
      display: inline-block;
      width: auto;
      position: relative;

      .search-icon {
        position: absolute;
        top: 14px;
        left: 14px;
        width: 18px;
        height: 18px;
        z-index: 20;
      }

      input {
        padding-left: 52px;
        border: 1px solid #E3E3E9;
        border-radius: 4px !important;
      }
    }

    .table_verify_search {
      width: 304px;
    }
  }
}

.verify-result {
  display: inline-block;
  padding: 4px 14px;
  border-radius: 20px;
  font-size: 13px;
  transition: all .2s ease-in;

  &.risky {
    color: #FFBE21;
    background-color: #FFF6E1;

    &:hover {
      background-color: #FFBE21;
      color: #ffffff;
    }
  }

  &.valid {
    color: #5DD38C;
    background-color: #EBF9F0;

    &:hover {
      background-color: #5DD38C;
      color: #ffffff;
    }
  }

  &.invalid {
    color: #ED5359;
    background-color: #FFF6F6;

    &:hover {
      background-color: #ED5359;
      color: #ffffff;
    }
  }

  &.unknown {
    color: #AE5ACC;
    background-color: #F6F3FC;

    &:hover {
      background-color: #AE5ACC;
      color: #ffffff;
    }
  }
}

.verify-result-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #C7CAD4;
  box-shadow: 0 0 0 4px rgb(199 202 212 / 30%);

  &.risky {
    background-color: #FFBE21;
    box-shadow: 0 0 0 4px rgb(255 190 33 / 30%);
  }

  &.valid {
    background-color: #5DD38C;
    box-shadow: 0 0 0 4px rgb(93 211 140 / 30%)
  }

  &.invalid {
    background-color: #ED5359;
    box-shadow: 0 0 0 4px rgb(237 83 89 / 30%);
  }

  &.unknown {
    background-color: #AE5ACC;
    box-shadow: 0 0 0 4px rgb(174 90 204 / 30%);
  }
}

.validated-email {
  display: inline-block;
  margin-left: 16px;
}

.table_verify {
  .t_row {
    div {
      font-size: 13px;
    }

    .t_time {
      color: #8B8B92;
    }
  }

  .t_row.t_header {
    .t_time {
      font-style: italic;
    }
  }
}

.single-proof-btn {
  display: flex;
  justify-content: end;
}

.clear-history-btn {
  position: absolute;

  color: #8B8B92 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  z-index: 5;
  top: 24px;
  margin-left: 8px;

  &:hover {
    text-decoration: underline;
  }
}
