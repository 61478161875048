@media (max-width: 768px) {
  .account_content_section {
    padding: 16px !important;
    margin-top: 16px !important;

    .line {
      margin: 0 -16px !important;
    }

    .account-image {
      margin: 16px 0 0 0 !important;
    }

    .account-email-checkboxes-section {
      width: auto !important;
      margin: 0 0 16px 0 !important;
    }
    .payment-methods-table {
      td {
        padding: 13px 16px !important;
        font-size: 13px !important;
      }
    }
    .table-cell_column {
      min-width: max-content !important;
    }
  }
  h1 {
    margin-bottom: 16px !important;
  }
  .account-form {
    flex-direction: column !important;
    align-items: center !important;
  }
  .account-data {
    flex-direction: column !important;
  }

  .account-data-row {
    flex-direction: column !important;
    margin: 0 !important;
  }

  .account-form-group {
    margin: 16px 0 0 0 !important;

    label {
      margin-bottom: 8px !important;
    }
  }
  .account-warning {
    padding: 16px !important;
    margin-top: 16px !important;
    flex-direction: column !important;
    align-items: normal !important;

    .text {
      width: auto !important;
      margin-bottom: 16px !important;
    }
  }
  .delete-modal {
    width: 100% !important;
    margin: 0 !important;
    padding: 40px !important;
  }
  .modal-dialog {
    width: 100% !important;
  }
  .account-data {
    width: 100% !important;
  }
  .navbar-expand {
    justify-content: space-between !important;
    gap: 20px !important;
  }
  .form-email {
    flex-direction: column !important;
  }
  .low-balance {
    margin-top: 16px !important;
  }
  .account-threshold-group {
    margin: 0 0 16px 0 !important;
  }
  .account-send-notification-group {
    margin-bottom: 16px !important;
  }

  .account-form-common {

    .account-form-row {
      margin-bottom: 0 !important;
      flex-direction: column !important;

      .form-group {
        margin: 16px 0 0 0 !important;
      }

      .form-label {
        margin-bottom: 10px !important;
      }
    }
  }

  .mobile-mt-16 {
    margin-top: 16px !important;
  }

  .mobile-btn-width {
    width: 100% !important;
  }

  .account-invoice-header-comment {
    width: auto !important;
    margin-bottom: 16px !important;
  }

  .account-settings-navbar {
    .nav-link {
      margin-right: 0 !important;
    }
    .navmenu {
      min-width: auto !important;
    }
  }
}

.account-payment-methods-box {
  overflow-x: scroll !important;
  scrollbar-width: thin !important;
  scrollbar-color: #5DD38C #fff !important;
}

.account-threshold-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.account-settings-navbar {
    &.navbar {
      border-bottom: 1px solid #E3E3E9;
      padding-top: 0;
      padding-bottom: 0;
    }

    .nav-link {
      margin-right: 32px;
      margin-bottom: -1px;
    }

    .navmenu {
      padding: 0 3px 16px;
      font-size: 16px;
    }
}


.account_content_section {
  padding: 32px 32px 48px;
  background: #FFFFFF;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;
  margin-top: 32px;

  .payment-methods-table {
    width: 100%;
    tbody {
      tr {
        border-bottom: 1px solid #E3E3E9;
      }
      td {
        font-size: 16px;
        padding: 16px 32px;
        gap: 32px;
        line-height: 24px;
      }
    }

    .table-no-content {
      text-align: center;
    }
  }

  .disabled-title {
    color: #8B8B92;
  }

  .payment-methods-set-default {
    display: flex;
    justify-items: center;
    justify-content: end;
  }

  .payment-methods-row {
    display: flex;
    flex-direction: row;
    grid-gap: 0;
    gap: 0;
    justify-items: center;
    align-items: center;
  }

  .payment-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 148%;
  }

  .table-cell_header {
    font-size: 13px;
    color: #8B8B92;
    margin-bottom: 4px;
    line-height: 20px;
  }

  .table-cell_column {
    display: flex;
    flex-direction: column;
  }

  .account-invoice-header-comment {
    font-size: 16px;
    line-height: 20px;
    width: 50%;
    margin-bottom: 32px;
    margin-top: -16px;
  }

  h1 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 32px;
  }

  .payment-methods-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
  }

  .block-heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }

  .payment-methods-line {
    height: 1px;
    background: #E3E3E9;
    flex: none;
  }

  .subscribe-package-title {
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    color: #171718;
  }

  .subscribe-title {
    color: #171718;
  }

  .subscription-canceled {
    color: #ED5359;
  }

  .subscription-btn {
    width: 130px;
  }

  .line {
    height: 1px;
    background: #E3E3E9;
    flex: none;
    margin: 0 -32px;
    margin-bottom: 32px;
  }

  .rounded-circle {
    border: 1px dashed #C7CAD5;
    padding: 8px;
    margin: 16px
  }

  .account-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .account-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 160px;
    height: 200px;
    background: #FFFFFF;
    border: 1px solid #E3E3E9;
    box-sizing: border-box;
    box-shadow: 0 2px 0 #EBECF0;
    border-radius: 10px;
    margin-right: 32px;

    .link {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      color: #32B767;
      cursor: pointer;
    }
  }

  .account-btn-icon {
    margin-right: 6px;
  }

  .account-data {
    display: flex;
    flex: 1;
    flex-direction: column;

    .account-data-row {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 32px;

      .account-form-group {
        flex: 1;
        margin-right: 32px;

        &:last-child {
          margin-right: 0;
        }

        label {
          font-size: 16px;
          margin-bottom: 16px;
          font-weight: 500;
        }

        &.form-buttons {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          align-content: center;
        }
      }
    }
  }

  .account-warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    padding: 32px 24px;

    background: #FFF7F7;
    border: 1px dashed #ED5359;
    box-sizing: border-box;
    border-radius: 4px;

    .text {
      width: 60%;
      h3 {
        font-size: 20px;
        margin-bottom: 16px;
      }

      span {
        font-size: 13px;
        line-height: 17px;
      }
    }
  }

  .form-email {
    display: flex;
    width: 100%;
  }

  .account-email-checkboxes-section {
    margin-right: 32px;
    width: 40%;
  }

  .account-email-checkboxes-title {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.02em;
    font-weight: 500;
    margin-bottom: 32px;
  }

  .checkbox-settings {
    margin-bottom: 16px;
  }

  .low-balance {
    margin-top: 32px;

    .low-balance-title {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.02em;
      font-weight: 500;
      margin-bottom: 16px;
    }

    label {
      color: #8B8B92
    }
  }
}

.account-email-input-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  textarea {
    color: #32B767;
    font-size: 16px;
    &:focus {
      color: #32B767;
    }
  }
  .account-email-btn {
    display: flex;
    justify-content: end;
  }
}

.account-form-common {

  .password-input {
    position: relative;
  }

  label {
    font-weight: 500;
    font-size: 16px;
    color: #171718;
    margin-bottom: 16px;
  }

  label {
    font-weight: 500;

    &.label-italic {
      font-style: italic;
      font-weight: 400;
    }
  }

  .label-italic {
    &::placeholder {
      font-style: italic;
    }
  }

  .account-form-row {
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;

    .form-group {
      flex: 1;
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .account-from-controls {
    text-align: right;
  }

  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.account-btn-delete {
  color: #8B8B92;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.change-password-form .form-control.is-invalid{
  //background-color: #FFF6F6;
  border: 1px solid #ED5359;
  background-image: none !important;
}

/*


.account_content_section

.account_content_security_settings{
  display: flex;
  flex-direction: row;
}

.account_content_small_section{
  display: flex;
  flex-direction: column;
  padding: 32px 32px 48px;

  width: 416px;
  margin: 32px 64px;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px #EBECF0;
  border-radius: 10px;
  flex: none;
  flex-grow: 0;
}


.account-email-checkboxes-section{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.account-email-input-section{
  margin-left: 128px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.account-invoice-control-section{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.account-invoice-control{
  width: 288px;
  height: 48px;
}


.account-email-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 157px;
  height: 48px;
}

.account-yourprofile-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 157px;
  height: 48px;
  margin: 44px 0px 0px 32px;
}

.delete-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 191px;
  height: 46px;
}
.delete-button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 128%;
  color: #FFFFFF;
}



.account-delete-control{
  width: 200px;
  height: 48px;
}

.delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 32px;
  height: 320px;
}

.form-changepassword {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.account-changepassword-control-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%
}

.account-changepassword-control {
  width: 348px;
  height: 48px;
}

.account-changepassword-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 48px;
}

.account-email-input-header {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #171718;
}*/
