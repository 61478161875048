// правки от KV89417
body {
  overflow-y: scroll;
}
.signin{
  &-wrapper {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  &-logo {
    margin-top: 45px;
    margin-bottom: 0;
  }
  &-card {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    margin-top: 0;
    &-body {
      width: 484px;
    }
  }
  &-header {
    font-size: 28px;
    margin-bottom: 25px;
  }
  &-form{
    .form-label {
      font-weight: 500;
      margin-bottom: 10px;

    }
    input[type=text],
    input[type=email],
    input[type=password] {
      height: 52px;
    }
    .invalid-feedback {
      padding-top: 8px;
      font-size: 13px;
      line-height: 120%;
    }
  }
  &-no-account {
    margin-top: 20px;
  }
}//.signin
.signup-submit-form {
  margin-top: 12px;
}
.sign-in-copy {
  position: relative;
  width: auto;
  bottom: 0;
  padding-bottom: 30px;
}
.signin-card .forgot-password-title {
  font-size: 28px;
}
.forgot-password-link{
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
  &__to-go svg {
    margin-right: 8px;
  }
  a{
    color: #32B767 !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 148%;
    text-decoration: none;
  }
}
#navbarScrollingDropdownMob{
  &::after {
    display: none;
  }
}
.dropdown-menu{
  &[aria-labelledby="navbarScrollingDropdownMob"]{
    width: 260px;
  }
  .account-credits {
    display: block;
    margin: 0 0 20px;
    text-align: center;
  }
  .btn.btn-outline-success.btn-buy-credits {
    display: block;
    margin: 0 auto 20px;
  }
  .account-controls {
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
  }
}
// payment
.main_navbar {
  width: 1650px;
  margin: 0 auto;
}
.page {
  padding-top: 33px;
  width: 1650px;
  margin: auto;
  &-menu{
    margin-bottom: 28px;
    &__text{
      font-size: 13px;
      line-height: 19px;
    }
  }
  &-payment {
    max-width: 1650px;
    margin: auto;
  }
}
.packages .package-offers .package-offer {
  padding-top: 4px;
  padding-bottom: 4px;
}
.payment_key_features{
  padding: 64px 64px 48px;
  &__wrap{
    width: 1520px;
    margin: auto;
  }
  h3 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 28px;
  }
}
.key_feature_columns {
  justify-content: flex-start;
  .key_feature_column {
    flex: 0 0 440px;
  }
}
.payment_common_questions {
  padding: 60px 0;
  h3 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 44px;
  }
}
.questions_columns {
  .accordion-item {
    margin: 0 0 14px 0;
  }
  .accordion-button {
    padding: 0.7rem 1.25rem 0.7rem 2.85rem;
    &::after {
      left: 15px;
    }
  }
}
.questions_column {
  flex: 0 755px;
}
.payment-summary-result {
  width: 585px;
}
.payment-credits{
  .payment-credits-head {
    margin-bottom: 23px;
  }
  .payment-credits-input input {
    padding: 14px 20px 14px 20px;
  }
  .payment-credits-select .payment-credits-item {
    padding: 20px 25px;
    width: 32%;
    margin-bottom: 16px;
    &.selected {
      outline: 1px solid #C8EFD8;
    }
    .credits-text {
      margin: 0 -3px 0 0;
    }
  }
}
.payment-summary{
  .payment-selected-credits {
    padding-bottom: 2px;
    .selected-credits {
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 0;
    }
    .selected-comment {
      margin-bottom: 7px;
    }
  }

  .payment-per-credit-cost {
    margin-bottom: 16px;
  }
  .payment-summary-price {
    padding-top: 18px;
    padding-bottom: 20px;
    .discount {
      margin-bottom: 7px;
      .new-price {
        padding: 5px 20px;
      }
    }

    .summary-price {
      font-size: 52px;
      line-height: 52px;
    }
  }
  .payment-button a.btn {
    padding: 13px 32px;
    height: 50px;
  }
}
.payment-calculator {
  padding-bottom: 33px;
  margin-bottom: 40px;
}


@media (max-width: 1650px) {
  .page,
  .main_navbar,
  .payment_key_features__wrap{
    width: auto;
    margin: 0;
  }
  .key_feature_columns {
    justify-content: space-between;
    .key_feature_column {
      flex: auto;
    }
  }
  .questions_column {
    flex: 1 1;
  }
  .payment-summary-result {
    width: 496px;
  }
}

// PageModal
.integration-card {
  color: #8B8B92;
  margin: 21px 16px 21px 24px;

  &__title {
    margin-bottom: 16px;
    position: relative;
    padding: 24px 24px 0;
  }

  &__search {
    position: relative;

    input {
      padding: 13px 23px 13px 54px;
      color: #8B8B92;
      font-size: 16px;
      line-height: 128%;
      border-radius: 4px;
      border: 1px solid #E3E3E9;
      background: #FFF;
      outline: 0;
      width: 100%;
    }

    label {
      background: transparent url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.4522 14.4529L18.3327 18.3334' stroke='%238B8B92' stroke-width='1.2' stroke-linecap='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.16601 16.6667C13.3082 16.6667 16.666 13.3089 16.666 9.16675C16.666 5.02461 13.3082 1.66675 9.16601 1.66675C5.02388 1.66675 1.66602 5.02461 1.66602 9.16675C1.66602 13.3089 5.02388 16.6667 9.16601 16.6667Z' stroke='%238B8B92' stroke-width='1.2'/%3E%3C/svg%3E") center/22px auto no-repeat;
      width: 22px;
      height: 22px;
      position: absolute;
      left: 18px;
      top: 13px;
    }
  }

  &__list {
    opacity: 0.5;
    list-style: none;
    padding: 0;
    margin-top: 16px;
    li{
      margin: 12px 0;
    }
    a {
      display: block;
      text-decoration: none;
    }
    img {
      margin-right: 12px;
      height: 40px;
      width: 40px;
      filter: grayscale(100%);
    }
  }

  .coming-soon-title {
    text-align: center;
  }
}//.integration-card


.clean-upload-card {
  margin: 18px 16px 18px 24px;
  padding: 6px 20px 8px;
  &-text {
    color: #171718;
    font-weight: 500;
    line-height: 148%;
    margin-left: 11px;
  }
}

.modal-right__wrap{
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;


  scrollbar-width: thin;          /* "auto" или "thin"  */
  scrollbar-color: #5DD38C #fff;   /* плашка скролла и дорожка */
  &::-webkit-scrollbar {
    width: 8px;               /* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #fff;        /* цвет дорожки */
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #5DD38C;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
    border: 0;  /* padding вокруг плашки */
  }
}


.clean-modal-header {
  color: #171718;
  margin-bottom: 32px;
  width: 100%;

  font-size: 28px;
  font-weight: 500;
  line-height: 128%;
  letter-spacing: 0.56px;
}

.modal-upload-file .btn-close {
  width: 20px;
  height: 20px;
  padding: 0;
  background: transparent url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.9985 17.999L2.0001 2.00063' stroke='%238B8B92' stroke-width='1.2' stroke-linecap='round'/%3E%3Cpath d='M17.9999 2.00161L2.00151 18' stroke='%238B8B92' stroke-width='1.2' stroke-linecap='round'/%3E%3C/svg%3E") center/20px auto no-repeat;

}
.modal-upload-file .btn-close-absolute {
  right: 32px;
  top: 31px;
}
.clean-modal-list {
  padding-left: 22px;
  li {
    margin-bottom: 0;
  }
}

.clean-modal-message {
  width: 100%;
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  &__text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -3px;
    color: #171718;
    font-size: 16px;
    font-weight: 500;
    line-height: 148%;
    &_error{
      color: var(--fill-red, #ED5359);
      font-size: 13px;
      font-weight: 400;
      line-height: 152%;
    }
  }
  &__progress_status{
    color: #FFBE21;
    text-align: right;
    font-size: 13px;
    font-weight: 500;
    line-height: 128%;
  }
}




input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: #EEEFF2;
  height: 8px;
  border-radius: 20px;
  overflow: hidden;
}
input[type='range']::-webkit-slider-runnable-track {
  height: 8px;
  -webkit-appearance: none;
  color: #EEEFF2;
}
input[type='range']::-webkit-slider-thumb {
  width: 8px;
  border-radius: 20px;
  -webkit-appearance: none;
  height: 8px;
}

input[type='range'].clean-modal-message__ok_range::-webkit-slider-thumb {
  background: #5DD38C;
  box-shadow: -400px 0 0 396px #5DD38C;
}
input[type='range'].clean-modal-message__progress_range::-webkit-slider-thumb {
  background: #FFBE21;
  box-shadow: -400px 0 0 396px #FFBE21;
}
input[type='range'].clean-modal-message__error_range::-webkit-slider-thumb {
  background: #ED5359;
  box-shadow: -400px 0 0 396px #ED5359;
}


.cleanFile-warning {
  padding: 23px 20px 23px 66px;
  position: relative;
  height: auto;
  &:before{
    content: "";
    width: 32px;
    height: 32px;
    background-image: url("../../public/images/clean-file-warning.svg");
    position: absolute;
    top: 50%;
    left: 19px;
    transform: translateY(-50%);
  }
  h2 {
    margin-bottom: 6px;
  }
}

.cf_cleaning {
  padding: 0;
  .alert {
    padding: 20px 19px;
    height: auto;
    font-size: 13px;
    font-weight: 400;
    line-height: 152%;
  }
}
.cf_cleaning .cf_cleaning_btn,
.cf_cleaning .cf_alert,
.cf_cleaning .cf_delete,
.cf_cleaning .btn {
  margin-left: 0;
  margin-right: 0;
}
.cf_validation_results{
  margin: 0;
  flex: 1 1;
  height: auto;
  padding: 20px;
}

.verify-result {
  padding: 5px 20px;
}
.table_verify .t_row div.t_email {
  flex: 1 0 0;
}
.t_row:hover .verify-result.invalid {
  background-color: #ED5359;
  color: #ffffff;
}
.t_row:hover .verify-result.unknown {
  background-color: #AE5ACC;
  color: #ffffff;
}
.t_row:hover .verify-result.valid {
  background-color: #5DD38C;
  color: #ffffff;
}
.t_row:hover .verify-result.risky {
  background-color: #FFBE21;
  color: #ffffff;
}


/*
.cleanFile-summary .cleanFile-summary-name span {
  display: block;
  padding-left: 50px;
  animation: moving-line 25s linear infinite;
}
.cleanFile-summary .cleanFile-summary-name span:hover {
  animation-play-state: paused;
}
 */
@keyframes moving-line {
  0%   { transform: translate(0, 0); } /*початок переміщення*/
  100% { transform: translate(calc(-100% + 130px), 0); } /*переміщення вздовж осі X*/
}


//.cleanFile-summary .cleanFile-summary-name span {
//  padding-left: 0;
//  animation: none;
//}
//.cleanFile-summary {
//  flex: 0 0 272px;
//}
//.cleanFile-summary .cleanFile-summary-row {
//  width: 272px;
//}

//.cf_cleaning {
//  flex: 0 0 272px;
//  // justify-content: space-between;
//}

//.cf_cleaning button.btn {
//  width: 100%;
//}

.cf_cleaning button.btn.cf_delete  {
  color: #8B8B92;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  height: auto;
  padding: 0;
  margin: 0;
}
.cf_cleaning .alert.alert-light {
  padding: 0 20px;
  height: auto;
  font-size: 13px;
  width: 100%;
  text-align: center;
  border: none;
}
.cleanFile-info {
  flex: 1;
}
.cleanFile-analyze button.cf_btn.cf_delete{
  color: #8B8B92;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  height: auto;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
}
.cf_validation_results .cf_tablechart {
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 15px
}

.cf_validation_results .cf_table_row {
  justify-content: space-between;
}


a.btn.btn-outline-success:hover,
button.btn.btn-outline-success:hover {
  color: #fff !important;
  border: 1px solid #5DD38C;
  background-color: #5DD38C;
}
a.btn.btn-outline-danger:hover,
button.btn.btn-outline-danger:hover {
  background: #FFF6F6;
  opacity: 0.6;
}
a.btn.btn-outline-secondary,
button.btn.btn-outline-secondary {
  color: #171718 !important;
  border: 1px solid #C7CAD5;
}
a.btn.btn-outline-secondary:hover,
button.btn.btn-outline-secondary:hover {
  opacity: 0.6;
  background: #F4F5F9;
}
.clean_files_head .clean_files_filters .clean_files_filter {
  border-bottom: 1px solid transparent ;
}
.clean_files_head .clean_files_filters .clean_files_filter:hover {
  border-bottom: 1px solid;
}
.dropzone:hover {
  background: rgba(93, 211, 140, 0.2);
}


input[name="search"]{
  outline: none;
  border: 1px solid #E3E3E9;
}
input[name="search"]:focus-visible,
input[type="search"]:focus-visible{
  outline: none;
  border: 1px solid #E3E3E9;
}
.page .result-card .result-history-search .input-group input {
  border: 1px solid #E3E3E9;
}
input[type="search"]:hover,
input[type="text"]:hover:not(.DateInput_input),
input[type="number"]:hover,
.page .result-card .result-history-search .input-group input:hover,
textarea:hover,
select:hover{
  border:#5DD38C 1px solid;
  box-shadow: 0 0 5px #5DD38C;
  -webkit-box-shadow: 0 0 5px #5DD38C;
  -moz-box-shadow: 0 0 5px #5DD38C;
}
.DateInput input:focus{
  border: none;
  box-shadow: none;
}

.DateRangePicker:hover .DateRangePickerInput__withBorder {
  border: 1px solid #5DD38C;
  border-radius: 4px;
  box-shadow: 0 0 5px #5DD38C;
  -webkit-box-shadow: 0 0 5px #5DD38C;
  -moz-box-shadow: 0 0 5px #5DD38C;
}

.result-card .result-history-search .input-group input {
  padding-left: 48px;
  border: 1px solid #171718;
}
.page-menu-buttons .btn {
  padding: 13px 22px 13px 22px;
}
.page-menu-buttons .btn.btn-success {
  border: 1px solid #5DD38C;
  background-color: #5DD38C;
}
.page-menu-buttons .btn.btn-success:hover {
  border: 1px solid #67E499;
  background-color: #67E499;
}
a.btn.btn-success,
button.btn.btn-success {
  background: #5DD38C;
}
.btn.btn-success:hover {
  background: #67E499;
}
.navbar-light .navbar-nav .nav-link .navmenu {
  border-bottom: solid 2px transparent;
}
.navbar-light .navbar-nav .nav-link:hover .navmenu{
  border-bottom: solid 2px #C7CAD5;
}
.navbar-light .navbar-nav .nav-link .navmenu.active{
  border-bottom: solid 2px #5DD38C;
}
#navbarScrollingDropdown:hover .rounded-circle {
  box-shadow: 0 0 6px #939393;
  transition: .3s;
}
.dropdown-menu {
  box-shadow: 1px 1px 5px 2px #EBECF0;
  border: 1px solid #EBECF0;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  top: 1px;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0;
}

.page-verify .input-card {
  height: max-content;
}
.page-verify .input-card p.text-danger {
  padding-bottom: 16px;
}
.table_verify .t_row:not(.t_header):hover{
  background: #f4f5f980;
}
.table_verify .t_row div.t_email {
  padding-top: 6px;
}


.app-card-created {
  width: calc(25% - 32px);
  transition: all 0.15s ease-in-out;
}
.app-card-progress {
  width: 100%;
}
.app-card-created:hover{
  border: 1px solid #5DD38C;
  //box-shadow: 0 0 5px #E3E3E9;
  //-webkit-box-shadow: 0 0 5px #E3E3E9;
  //-moz-box-shadow: 0 0 5px #E3E3E9;
}
.integration-card__list a:hover {
  background: rgba(93, 211, 140, 0.1);
  border-radius: 20px 0 0 20px;
}

.btn .img-celare,
.btn:hover .img-visibile{
  display: none;
}
.btn:hover .img-celare{
  display: inline-block;
}



.dropdown-menu .account-controls .dropdown-item.active,
.dropdown-menu .account-controls .dropdown-item:active{
  background-color: transparent;
  border-bottom: 0 solid transparent;
}
.download-modal-extended .download-tabs .download-tab.selected h2 {
  font-style: normal;
}

.settings-accordion:hover .settings-accordion-header {
  background: #F4F5F9;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
}
.theme-checkbox:hover input[type=checkbox] + label::before{
  content: "";
  display: block;
  position: absolute;
  text-align: center;
  height: 20px;
  width: 20px;
  left: 0;
  top: 0;
  border-radius: 2px;
  border: 1px solid #5DD38C;

}

.au-data .au-card:hover {
  background: #F8F8FB;
}
.au-data .au-card.au-card-enabled {
  background-color: rgba(92, 211, 140, 0.1);
}

.form-check:hover .form-check-input[type=checkbox] {
  border: 1px solid #5DD38C;
  // border-radius: 2px;
}
.questions_column .accordion-button:not(.collapsed) {
  color: #fff;
}
.custom-api-key .collapse-icon{
  cursor: pointer;
}
.au-js-link-section .copy-link-button{
  width: auto;
}
.au-js-link-section>.copy-link-button .btn-icon-white{
  display: none;
}
.au-js-link-section>.copy-link-button:hover .btn-icon-white{
  display: inline-block;
}

.au-js-link-section>.copy-link-button:hover .btn-icon-green{
  display: none;
}


.au-data .au-card.au-card-enabled .requests-status.total {
  background-image: url("../../public/images/appUsage/total_requests-active.svg");
}
.au-data .au-card.au-card-enabled .requests-status.single {
  background-image: url("../../public/images/appUsage/single_requests-active.svg");
}
.au-data .au-card.au-card-enabled .requests-status.bulk {
  background-image: url("../../public/images/appUsage/bulk_requests-active.svg");
}
.au-data .au-card.au-card-enabled .requests-status.other {
  background-image: url("../../public/images/appUsage/other_requests-active.svg");
}


.cf_cleaning.cf_processing .alert.alert-light{
  padding: 0;
  margin: 16px 0;
}
.cf_cleaning.cf_processing .btn-warning:disabled,
.cf_cleaning.cf_processing .btn-warning.disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  opacity: 0.85;
}
.processing-anim{
  margin-left: 16px;
  animation-name: rotation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotation {
  0% {
    transform:rotate(0deg);
  }
  100% {
    transform:rotate(360deg);
  }
}
.cf_cleaning.cf_processing .upload-progress {
  height: 20px;
}
.cf_cleaning.cf_processing .progress-bar::after {
  content: attr(aria-valuenow) ' %';
  position: absolute;
  margin-left: 18px;
  font-family: sans-serif;
  font-weight: 600;
}
.bg-success {
  --bs-success-rgb: 93, 211, 140;
}

.cf_table_row>div{
  flex: 0 1 0;
}

.cf_table_row .cf_table_percent {
  flex: 0 0 55px;
}

.search input[name=search] {
  border-radius: 4px !important;
}

.cf_table_name {
  width: auto;
  min-width: 60px;
}

input.table_verify_search::placeholder{
  color: #9fa5aa8a !important;
}

input[type=search]::placeholder{
  color: #9fa5aa8a !important;
}

input::-webkit-input-placeholder {
  color: #9fa5aa8a !important;
}

input:-moz-placeholder {
  color: #9fa5aa8a !important;
}

input::-moz-placeholder {
  color: #9fa5aa8a !important;
}

input:-ms-input-placeholder {
  color: #9fa5aa8a !important;
}

input::-ms-input-placeholder {
  color: #9fa5aa8a !important;
}

form select {
  color: #757d85 !important;
}

.form-floating > label {
  color: #9fa5aa8a;
}

.app-usage-stats-header .stats-type select {
  font-style: normal;
}

button.btn.copy-link-button{
  padding: 11px 32px 11px 30px;
}

.app-card-created:hover {
  // box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.create-app-buttons .btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-popup-card .edit-account .btn {
  padding: 11px 22px 11px 22px;
}

.account-warning>button.btn {
  padding: 11px 32px 11px 26px;
}

.cf_table_row > div > div {
  white-space: nowrap;
}

.loadingFiles{
  margin: auto;
  width: 100px;
  height: 100px;
  background-image: url("../../public/images/loader.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  font-size: 0;
}

.modal {
  overflow-y: scroll;
}

.settings-accordion .options-list,
.presets-description{
  display: none;
  opacity: 0;
}

.settings-accordion .options-list.show-options,
.presets-description.open{
  display: block;
  opacity: 1;
  animation: fadeInFromNone 0.5s ease-in-out;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(-15px);
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
    transform: translateY(0px);
  }
}

.icon.icon-arrow-down,
.icon.icon-arrow-up {
  width: 20px;
  height: 20px;
}

.form-check-input:checked[type=checkbox] {
  background-color: #67E499 !important;
  border: 1px solid #67E499;
}

.au-data .au-card.au-card-enabled:hover {
  background-color: rgba(92, 211, 140, 0.2);
}

.delete-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin: 40px;
}

.delete-modal .form-control {
  margin-bottom: 20px;
}

.delete-modal .form-label {
  align-self: start;
}

.delete-modal .delete-button {
  display: flex;
  align-items: center;
}

.delete-modal .delete-button-text {
  padding-top: 3px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  opacity: 0;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.result-card button.btn.export-to-csv:active,
.result-card button.btn.export-to-csv:active:focus,
.result-card button.btn.export-to-csv:focus {
  opacity: 1;
}



/* RESET STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.results-chart {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.results-chart *,
.results-chart::before {
  box-sizing: border-box;
}


/* results-chart STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.results-chart {
  position: relative;
  width: 278px;
  height: 139px;
}

.results-chart::before,
.results-chart::after {
  position: absolute;
}

.results-chart::before {
  content: '';
  width: inherit;
  height: inherit;
  border: 70px solid rgba(211, 211, 211, .3);
  border-bottom: none;
  border-top-left-radius: 175px;
  border-top-right-radius: 175px;
}

.results-chart li {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 70px solid;
  border-top: none;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
  transform-origin: 50% 0;
}

.results-chart li:nth-child(1) {
  z-index: 4;
  border-color: rgb(93, 211, 140);
}

.results-chart li:nth-child(2) {
  z-index: 3;
  border-color: rgb(255, 190, 33);
}

.results-chart li:nth-child(3) {
  z-index: 2;
  border-color: rgb(227, 227, 233);
}

.results-chart li:nth-child(4) {
  z-index: 1;
  border-color: rgb(237, 83, 89);
}

.results-chart {
  /* existing rules....*/

  overflow: hidden;
}

.results-chart li {
  /* existing rules....*/

  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.results-chart span {
  position: absolute;
  font-size: 12px;
  top: 0px;
  left: -55px;
}

.results-chart .results-chart__min_left span,
.results-chart .results-chart__min_right span{
  position: absolute;
  font-size: 12px;
  left: 2px;
}

.results-chart .results-chart__min_left span {
  top: -13px;
}

.results-chart .results-chart__min_right span {
  top: -4px;
}

.results-chart .results-chart__min_left span::before {
  content: '—';
  padding-right: 6px;
  position: relative;
  top: 3px;
}

.results-chart .results-chart__min_right span::after {
  content: '—';
  padding-left: 6px;
  position: relative;
  top: 3px;
}

#floatingTextarea2.errorClass {
  border: #ED5359 1px solid;
}

#floatingTextarea2.errorClass:hover {
  box-shadow: 0 0 5px #ED5359;
  -webkit-box-shadow: 0 0 5px #ED5359;
  -moz-box-shadow: 0 0 5px #ED5359;
}

.settings-accordion .options-list {
  padding: 24px 52px;
}

.settings-accordion .options-list .settings-accordion-option:last-child{
  border-bottom: 0;
}

.presets-description.open {
  padding-left: 64px;
  padding-right: 64px;
}

.ml-32 {
  margin-left: 32px !important;
}