
.page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 40px 64px 100px;
}

@media (max-width: 768px) {
  .page {
    padding: 2.5em 1em;
  }
}

.page-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
}

.page-menu-buttons {
  display: flex;
  justify-content: flex-end;
  align-content: center;
}

.page-content {
  margin: 0px 64px;
}

.page-green {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(93, 211, 140, 0.1);
  box-sizing: border-box;
  flex-grow: 1;
  align-self: stretch;
  border: 1px solid #5DD38C;
  border-radius: 10px;
}

.page-menu .container h1 {
  font-size: 32px;
  margin-bottom: 0;
}

.page-menu-buttons .btn {
  padding: 13px 28px 13px 20px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.page-menu-buttons .btn.btn-outline-success {
  background-color: transparent;
  color: #5DD38C;
  border: 1px solid #5DD38C;
}

.page-menu-buttons .btn.btn-success {
  border: 1px solid #5DD38C;
  background-color: #5DD38C;
}