@media (max-width: 768px) {
  .main_navbar {
    padding: 1em !important;
  }
  .btn.btn-outline-success.btn-buy-credits {
    width: calc(100% - 32px) !important;
    margin: 0 16px 16px 16px !important;
  }
}

.navmenu {
  padding: 24px 16px 22px 16px;
  display: inline-block;
  text-align: center;
  min-width: 90px !important;
  //&::after {
  //  display: block;
  //  content: attr(title);
  //  font-weight: bold;
  //  color: transparent;
  //  overflow: hidden;
  //  height: 1px;
  //  visibility: hidden;
  //  min-width: 48px;
  //}
}


#navbar-right {
  justify-content: flex-end;
}

#navbar-credits {
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active {
  border-bottom: solid 2px;
  border-bottom-color: #5DD38C;
  font-weight: bold;
  // text-shadow: 0 0 1px black, 0 0 1px black;
}

.cf_navmenu {
  margin-left: 20px;
}

.cf_active {
  font-weight: bold;
}

.disabled {
  color: grey;
}

.btn-signin {
  margin: 0px 5px 0px 5px;
}

.btn-page {
  margin-left: 5px;
  margin-right: 5px;
}

.btn-outline {

}

.nav {
  max-width: 800px;
  margin: 0px auto;
}

.nav-link {
  padding: 0px;
}

.main_navbar {
  min-height: 72px;
  padding: 0px 64px;
}

.navbar {
  border-bottom: solid 1px ;
  border-bottom-color: #ececf2;
}

#nav-brand {
  justify-content: flex-start;
}
#nav-signin {
  justify-content: flex-end;
}

.credits-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #171718;
  margin: 0 12px;
  white-space: nowrap;
}

.account-popup-li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 25px;
}

.account-popup-li .image-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  margin-right: 10px;
}

.account-popup-card {
  display: flex;
  flex-direction: row;
  margin: 20px;
}

.account-popup-gravatar {
  margin: 12px;
}

.main_navbar.navbar.navbar-expand {
  height: 72px;
  border-bottom: 1px solid #E3E3E9;
}

.btn.btn-outline-success.btn-buy-credits {
  padding: 5px 20px;
  width: 135px;
  height: 36px;
  font-weight: 400;
}

.account-credits {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  white-space: nowrap;
}

.account-credits .credits-text {
  vertical-align: middle;
}

.nav-bar-logo {
  margin: 0 110px 0 0;
}

.navbar-nav {
  margin-right: 32px;
}

.dropdown-menu {
  width: 312px;
  border-radius: 10px;
  padding-top: 32px;
  padding-bottom: 19px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 0px #EBECF0;
  border: none;
}

.account-popup-card.card {
  margin: 0;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
}
.account-popup-card.card .card-body {
  padding: 0;
}

.account-popup-card.card .card-body .card-title {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #171718;
  font-weight: 500;
  margin: 0;
}
.account-popup-card.card .account-email {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #8B8B92;
  margin-top: 8px;
}

.account-popup-card .dropdown-divider {
  border-top: 1px solid #E3E3E9;
}

.account-popup-card .edit-account {
  margin-top: 16px;
}

.edit-account-item {
  padding: 0;
  &:active,
  &:active:focus,
  &:hover,
  &:focus {
    background: none;
    box-shadow: none;
    opacity: 1;
  }
}


.account-popup-card .edit-account .btn {
  width: 168px;
  padding: 11px 28px 11px 20px;
}

.account-controls {
  padding-left: 34px;
  padding-right: 34px;
}

.account-controls .account-popup-li {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
}
