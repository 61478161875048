@media (max-width: 768px) {

  .app-usage-stats {
    margin-bottom: 24px !important;
  }

  .app-usage-stats-header {
    flex-direction: column !important;
    height: auto !important;
    padding: 16px !important;
    .stats-title h3 {
      line-height: inherit !important;
      margin-bottom: 16px !important;
    }
    .stats-type select {
      width: 100% !important;
      margin-bottom: 16px !important;
    }
  }

  .DateRangePicker {
    width: 100% !important;
    .DateRangePickerInput {
      width: 100% !important;
    }
  }

  .app-usage-data {
    margin-bottom: 0 !important;
    flex-direction: column !important;
  }

  .au-card {
    width: 100% !important;
    padding: 16px !important;
    height: auto !important;
  }

  .au-chart {
    padding: 16px 16px 16px 0 !important;
    height: 250px !important;
  }

  .recharts-cartesian-axis-tick {
    font-size: 12px !important;
  }

  .app-result-history {
    padding: 0 16px !important;
  }

  .app-usage-details-header {
    margin-bottom: 16px !important;
  }

  .table_clients {
    min-width: max-content !important;
    .t_row {
      padding: 0 !important;
      font-size: 13px !important;
    }
    .t_header {
      padding: 16px 0 !important;
    }
    .tc_date {
      width: 220px !important;
    }
    .tc_description {
      width: 150px !important;
    }
  }

  .content-section-body {
    overflow-x: scroll !important;
    scrollbar-width: thin !important;
    scrollbar-color: #5DD38C #fff !important;
    margin-bottom: 16px !important;
  }

  .app-js-settings-modal-form {
    .ca-form-row {
      margin-bottom: 0 !important;
      padding: 0 16px 16px !important;
    }
  }
  .app-js-settings-controls {
    padding: 0 16px 32px !important;
  }
  .app-js-usage-modal .app-usage-modal-header {
    padding-left: 16px !important;
  }

  .au-js-link {
    height: auto !important;
    width: auto !important;
    word-break: break-all !important;
  }

  .app-usage-modal {
    padding: 24px 16px !important;
  }

  .app-settings-modal-form {
    .ca-form-row {
      margin-bottom: 0 !important;
    }
  }
}

.app-usage {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 10px;
}

.app-usage-credentials {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 32px;

  .copy-link-button {
    display: inline-block;
    width: 150px;
    padding: 11px 0px 11px 0px;
    margin-right: 16px;
  }
}

.app-usage-link-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  border-bottom: #E3E3E9 1px solid;

  h2 {
    font-size: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
  }
}

span.highlight-tag {
  color: #AE5ACC;
}

a.reset-key {
  color: #32B767 !important;
  text-decoration: none;
  cursor: pointer;
}

.app-usage-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 32px 48px 32px;
}

.au-js-link-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.au-js-link {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 14px 20px;
  height: 48px;
  background: #F4F5F9;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
  font-size: 16px;
  font-style: italic;
  color: #8B8B92;
}

textarea.au-js-code {
  display: block;
  -webkit-appearance: none;
  width: 100%;
  padding: 8px 12px;
  background: #F4F5F9;
  border: 1px solid #E3E3E9;
  border-radius: 4px;
  font-size: 16px;
  color: #8B8B92;
  overflow: auto;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  resize: none;
  vertical-align: top;
  margin: 0;

  &:focus {
    outline: none !important;
  }
  &:hover {
    box-shadow: unset !important;
    -webkit-box-shadow: unset !important;
    border: 1px solid #E3E3E9;
    -moz-box-shadow: unset !important;
  }
}

.app-usage-details-header {
  display: flex;
  align-items: baseline;
  padding: 32px;
  border-top: #E3E3E9 1px solid;

  h3 {
    font-size: 20px;
    margin-right: 16px;
  }

  .app-usage-details-header-app-type {
    color: #8B8B92;
    font-size: 13px;
  }
}

.app-usage-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px;
}

.account-usage {
  .app-usage-data {
    border-bottom: #E3E3E9 1px solid;
    margin-bottom: 48px;
  }
}

.app-result-history {
  margin-top: 32px;
  padding: 0 32px 32px 32px;

  .app-usage-details-header {
    padding: 0;
    border-top: 0;
    margin-bottom: 32px;
  }

  .app-usage-details {
    padding: 0;

    .au-details {
      width: 100%;
    }
  }

  .payment_history_details_table {
    .t_header {
      font-size: 13px;
      line-height: 17px;
    }
  }
}

.app-usage-data {
  display: flex;
  flex-direction: row;
}

.au-js-btn-text {
  font-size: 16px;
  line-height: 128%;
  color: #5DD38C;
}

.au-js-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 150px;
  height: 48px;
  border: 1px solid #5DD38C;
  border-radius: 4px;
}

.au-refresh-link:hover {
  cursor: pointer;
}

.au-refresh-link {
  color: #5DD38C;
}

.au-select {
  width: 300px;
}

.au-chart {
  width: 100%;
  height: 519px;
  padding: 20px 32px
}

.au-data {
  display: flex;
  flex-direction: column;
}

.au-card {
  display: flex;
  flex-direction: row;
  width: 320px;
  border-right: #E3E3E9 1px solid;
}

.au-card:hover {
  cursor: pointer;
}

.au-card-total {
  height: 150px;
  padding: 42px 37px;
}

.au-card-overall {
  height: 150px;
  padding: 42px 37px;
}

.au-card-requests {
  height: 130px;
  padding: 32px 37px;
}

.au-card-results {
  height: 104px;
  padding: 19px 37px;
}

.au-card:not(:last-child) {
  border-bottom: #E3E3E9 1px solid;
}

.au-card-enabled {
  background-color: #F8F8FB;
}

.au-card-disabled {

}

.au-text-data {
  display: flex;
  flex-direction: column;
  margin-left: 24px;

}

.au-card-header {
  font-size: 16px;
}

.au-card-data {
  font-size: 32px;
}

.app-usage-modal {
  width: 100%;
  padding: 32px;

  h1 {
    font-size: 32px;
  }
}

.aum-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 32px;
}

.aum-confirm-btn {
  width: 100%;
}

.aum-btn {
  width: 49%;
}

.app-usage-stats {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 10px;
}

.app-usage-stats-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px;
  border-bottom: #E3E3E9 1px solid;
  height: 112px;

  .stats-title {
    vertical-align: middle;

    h3 {
      font-size: 20px;
      margin-bottom: 0;
      margin-top: 0;
      vertical-align: middle;
      line-height: 55px;
    }
  }

  .stats-type {
    select {
      font-style: italic;
      width: 567px;
    }
  }

  .stats-date-range {
    width: 345px;
  }
}

.results-status {
  width: 64px;
  height: 64px;
  border-radius: 30px;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;

  &.risky {
    background-color: #FFF6E1;
    background-image: url("../../../public/images/appUsage/status-risky.svg");

    &.filter-active {
      background-color: #FFBE21;
      background-image: url("../../../public/images/appUsage/status-risky-active.svg");
    }
  }

  &.valid {
    background-color: #EBF9F1;
    background-image: url("../../../public/images/appUsage/status-valid.svg");

    &.filter-active {
      background-color: #5DD38C;
      background-image: url("../../../public/images/appUsage/status-valid-active.svg");
    }
  }

  &.unknown {
    background-color: #F6F3FC;
    background-image: url("../../../public/images/appUsage/status-unknown.svg");

    &.filter-active {
      background-color: #AE5ACC;
      background-image: url("../../../public/images/appUsage/status-unknown-active.svg");
    }
  }

  &.invalid {
    background-color: #FFF6F6;
    background-image: url("../../../public/images/appUsage/status-invalid.svg");

    &.filter-active {
      background-color: #ED5359;
      background-image: url("../../../public/images/appUsage/status-invalid-active.svg");
    }
  }
}

.requests-status {
  width: 64px;
  height: 64px;
  border-radius: 30px;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 105%;

  &.overall {
    background-color: #82ca9d;
    background-image: url("../../../public/images/appUsage/total_requests.svg");
  }

  &.single {
    background-color: #8884d8;
    background-image: url("../../../public/images/appUsage/single_requests.svg");
  }

  &.bulk {
    background-color: #d87ebe;
    background-image: url("../../../public/images/appUsage/bulk_requests.svg");
  }

  &.other {
    background-color: #91ffc1;
    background-image: url("../../../public/images/appUsage/other_requests.svg");
  }
}

.custom-api-key {
  position: relative;
  margin-bottom: 32px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 0 #EBECF0;
  border-radius: 10px;

  .custom-api-key-header {
    background-color: #FFFFFF;
    border-radius: 10px;
    border-bottom: #E3E3E9 1px solid;
    padding: 24px 24px 24px 32px;
    height: 74px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 2px 2px rgba(93, 211, 140, 0.6);
    }

    &.open {

      &:hover {
        box-shadow: none;
      }
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    transition: all 0.15s ease-in-out;
  }

  .custom-api-key-content {
    padding: 24px 24px 24px 32px;
  }

  .copy-link-button {
    margin-right: 16px;
    width: 150px;
  }

  .custom-api-key-description {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .collapse-icon {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../../public/images/apps/chevron-down.svg");
    background-repeat: no-repeat;
    z-index: 10;
    top: 27px;
    right: 20px;

    &.close {
      transform: rotate(180deg);
    }
  }

  &.close {
    .custom-api-key-content {
      display: none;
    }

    .custom-api-key-header {
      border-bottom: #E3E3E9 0 solid;
    }
  }
}

.stats-title {
  width: 284px;
}

.usage-filters {
  display: flex;

  .stats-type {
    margin-right: 32px;
  }

  .stats-date-range {
    width: 345px;

    .DateRangePicker {
      width: 100%;
    }
  }
}

.modal-button {
  width: 168px;
  height: 48px;
}

.adm-warning {
  margin: 32px 0px;
  padding: 20px 24px;
  width: 100%;
  height: 60px;
  background: #FFF7F7;
  border: 1px dashed #ED5359;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #ED5359;
}

.adm-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}


.small-modal-content {
  padding: 32px;

  h3 {
    font-size: 32px;
    margin-bottom: 16px;
  }
}

.app-settings-modal-form {

  .ca-form-row {
    margin-bottom: 48px;
  }

  .ca-low-balance-block {
    margin-bottom: 48px;
  }

  .ca-send-notifications {
    margin-bottom: 32px;
  }
}

.app-js-usage-modal {
  width: 100%;
  h1 {
    font-size: 32px;
    margin: 0;
  }

  .app-usage-modal-header {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .ca-form-row {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.app-js-settings-modal-form {
  .ca-form-row {
    margin-bottom: 32px;
  }

  .line {
    border: 1px solid #E3E3E9;
    margin-bottom: 32px;
  }

  .ca-description-text {
    width: 100%;
  }

  .ca-low-balance-block {
    margin-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;

    h4,
    input,
    p,
    label {
      font-style: normal;
    }
  }

  .ca-row-comment {
    font-style: normal;
  }

  .ca-send-notifications {
    margin-bottom: 32px;
  }

  .ca-form-row {
    .form-text {
      font-size: 13px;
    }
  }

  .ca-throttle-usage-settings {
    .ca-allow-up-to,
    .ca-verifications-per {
      display: block;
    }

    .ca-allow-up-to {
      margin-bottom: 16px;
    }

    .form-label {
      width: 155px;
    }

    .ca-verifications-per-type.form-select {
      margin-right: 16px;
    }

  }
}

.app-js-settings-controls {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
}

.app-settings-alert {
  padding: 1em;
  margin: 0 32px 0 32px;
}

.app-usage-modal-header {
  margin-bottom: 32px;
}

.tc_center {
  &.tc_no_content {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #8B8B92;
  }
}
