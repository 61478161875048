@import "../variables";

.alert {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 32px;
    margin-bottom: 16px;
    border: 1px dashed $borderColor;
}

.alert-text {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.alert-buttons {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}